import './style.scss';

export const getPartnerListItemText = function (values = {}) {
	const { inn, kpp, iln, name } = values;

	const createPartnerRow = ({ title, text }) => {
		if (!text) return '';

		return (
			'<div class="partner-row-item">' +
			`<span class="title">${title}:</span>` +
			`<span class="text">${text}</span>` +
			'</div>'
		);
	};

	return (
		`<div class="partner-combo">` +
		`<div class="partner-name">${edi.utils.safeString(name)}</div>` +
		`<div class="partner-row">` +
		createPartnerRow({
			title: edi.i18n.getMessage('company.inn.short'),
			text: inn
		}) +
		createPartnerRow({
			title: edi.i18n.getMessage('company.kpp.short'),
			text: kpp
		}) +
		createPartnerRow({
			title: edi.i18n.getMessage('company.gln.short'),
			text: iln
		}) +
		'</div>' +
		`</div>`
	);
};
