import { DOCS_WITH_BUSINESS_STATE } from '@Edi/configuration';

/**
 * Configuration for fns documents
 * @author Vsevolod Liapin
 */
Ext.namespace('edi.constants');

Ext.merge(edi.constants, {
	DOCUMENT_TYPES: {
		DSF_EDI_FNS_ACT: 'DSF_EDI_FNS_ACT',
		DSF_EDI_FNS_INVOICE: 'DSF_EDI_FNS_INVOICE',
		EDI_FNS_UPD: 'EDI_FNS_UPD',
		EDI_FNS_UPD_P1: 'EDI_FNS_UPD_P1',
		EDI_FNS_UPD_P2: 'EDI_FNS_UPD_P2',
		EDI_FNS_UKD: 'EDI_FNS_UKD',
		EDI_FNS_UKD_P1: 'EDI_FNS_UKD_P1',
		EDI_FNS_UKD_P2: 'EDI_FNS_UKD_P2',
		EDI_FNS_UPD_SERVICE_AKT_P1: 'EDI_FNS_UPD_SERVICE_AKT_P1',
		EDI_FNS_UPD_SERVICE_AKT_P2: 'EDI_FNS_UPD_SERVICE_AKT_P2',
		EDI_FNS_DP_PDPOL: 'EDI_FNS_DP_PDPOL',
		EDI_FNS_DP_PDOTPR: 'EDI_FNS_DP_PDOTPR',
		EDI_FNS_DP_IZVPOL: 'EDI_FNS_DP_IZVPOL',
		EDI_FNS_ANNUL_DP_UVUTOUCH: 'EDI_FNS_ANNUL_DP_UVUTOUCH',

		EDI_FNS_UPD_STATUS_2_DP_PDPOL: 'EDI_FNS_UPD_STATUS_2_DP_PDPOL',
		EDI_FNS_UPD_STATUS_3_DP_PDOTPR: 'EDI_FNS_UPD_STATUS_3_DP_PDOTPR',
		EDI_FNS_UPD_STATUS_4_DP_IZVPOL: 'EDI_FNS_UPD_STATUS_4_DP_IZVPOL',
		EDI_FNS_UPD_STATUS_5_DP_IZVPOL: 'EDI_FNS_UPD_STATUS_5_DP_IZVPOL',
		EDI_FNS_UPD_STATUS_6_DP_IZVPOL: 'EDI_FNS_UPD_STATUS_6_DP_IZVPOL',
		EDI_FNS_UPD_STATUS_7_DP_PDOTPR: 'EDI_FNS_UPD_STATUS_7_DP_PDOTPR',
		EDI_FNS_UPD_STATUS_8_DP_IZVPOL: 'EDI_FNS_UPD_STATUS_8_DP_IZVPOL',
		EDI_FNS_UPD_STATUS_9_DP_UVUTOCH: 'EDI_FNS_UPD_STATUS_9_DP_UVUTOCH',
		EDI_FNS_UPD_STATUS_9_DP_PDPOL: 'EDI_FNS_UPD_STATUS_9_DP_PDPOL',
		EDI_FNS_UPD_STATUS_10_DP_IZVPOL: 'EDI_FNS_UPD_STATUS_10_DP_IZVPOL',
		EDI_FNS_UPD_STATUS_11_P2_DP_IZVPOL: 'EDI_FNS_UPD_STATUS_11_P2_DP_IZVPOL',
		EDI_FNS_UPD_STATUS_4_P1_DP_IZVPOL: 'EDI_FNS_UPD_STATUS_4_P1_DP_IZVPOL',

		EDI_FNS_UKD_STATUS_2_DP_PDPOL: 'EDI_FNS_UKD_STATUS_2_DP_PDPOL',
		EDI_FNS_UKD_STATUS_3_DP_PDOTPR: 'EDI_FNS_UKD_STATUS_3_DP_PDOTPR',
		EDI_FNS_UKD_STATUS_4_DP_IZVPOL: 'EDI_FNS_UKD_STATUS_4_DP_IZVPOL',
		EDI_FNS_UKD_STATUS_5_DP_IZVPOL: 'EDI_FNS_UKD_STATUS_5_DP_IZVPOL',
		EDI_FNS_UKD_STATUS_6_DP_IZVPOL: 'EDI_FNS_UKD_STATUS_6_DP_IZVPOL',
		EDI_FNS_UKD_STATUS_7_DP_PDOTPR: 'EDI_FNS_UKD_STATUS_7_DP_PDOTPR',
		EDI_FNS_UKD_STATUS_8_DP_IZVPOL: 'EDI_FNS_UKD_STATUS_8_DP_IZVPOL',
		EDI_FNS_UKD_STATUS_9_DP_UVUTOCH: 'EDI_FNS_UKD_STATUS_9_DP_UVUTOCH',
		EDI_FNS_UKD_STATUS_9_DP_PDPOL: 'EDI_FNS_UKD_STATUS_9_DP_PDPOL',
		EDI_FNS_UKD_STATUS_10_DP_IZVPOL: 'EDI_FNS_UKD_STATUS_10_DP_IZVPOL',
		EDI_FNS_UKD_STATUS_11_P2_DP_IZVPOL: 'EDI_FNS_UKD_STATUS_11_P2_DP_IZVPOL',
		EDI_FNS_UKD_STATUS_4_P1_DP_IZVPOL: 'EDI_FNS_UKD_STATUS_4_P1_DP_IZVPOL',

		EDI_FNS_UPD_STATUS_2_DP_PDPOL_DECREE_14: 'EDI_FNS_UPD_STATUS_2_DP_PDPOL_DECREE_14',
		EDI_FNS_UPD_STATUS_3_DP_PDOTPR_DECREE_14: 'EDI_FNS_UPD_STATUS_3_DP_PDOTPR_DECREE_14',
		EDI_FNS_UPD_STATUS_4_DP_PDOTPR_DECREE_14: 'EDI_FNS_UPD_STATUS_4_DP_PDOTPR_DECREE_14',
		EDI_FNS_UPD_STATUS_5_DP_IZVPOL_DECREE_14: 'EDI_FNS_UPD_STATUS_5_DP_IZVPOL_DECREE_14',
		EDI_FNS_UPD_STATUS_6_IOP_DP_PDPOL_DECREE_14: 'EDI_FNS_UPD_STATUS_6_IOP_DP_PDPOL_DECREE_14',
		EDI_FNS_UPD_STATUS_7_IOP_DP_PDOTPR_DECREE_14: 'EDI_FNS_UPD_STATUS_7_IOP_DP_PDOTPR_DECREE_14',
		EDI_FNS_UPD_STATUS_8_IOP_DP_PDOTPR_DECREE_14: 'EDI_FNS_UPD_STATUS_8_IOP_DP_PDOTPR_DECREE_14',

		EDI_FNS_UPD_STATUS_5_DP_UVUTOCH_DECREE_14: 'EDI_FNS_UPD_STATUS_5_DP_UVUTOCH_DECREE_14',
		EDI_FNS_UPD_STATUS_6_UOU_DP_PDPOL_DECREE_14: 'EDI_FNS_UPD_STATUS_6_UOU_DP_PDPOL_DECREE_14',
		EDI_FNS_UPD_STATUS_7_UOU_DP_PDOTPR_DECREE_14: 'EDI_FNS_UPD_STATUS_7_UOU_DP_PDOTPR_DECREE_14',
		EDI_FNS_UPD_STATUS_8_UOU_DP_PDOTPR_DECREE_14: 'EDI_FNS_UPD_STATUS_8_UOU_DP_PDOTPR_DECREE_14',
		EDI_FNS_UPD_STATUS_8_DP_PDOTPR_DECREE_14: 'EDI_FNS_UPD_STATUS_8_DP_PDOTPR_DECREE_14',
		EDI_FNS_UPD_STATUS_10_DP_PDOTPR_DECREE_14: 'EDI_FNS_UPD_STATUS_10_DP_PDOTPR_DECREE_14',

		EDI_FNS_UKD_STATUS_2_DP_PDPOL_DECREE_14: 'EDI_FNS_UKD_STATUS_2_DP_PDPOL_DECREE_14',
		EDI_FNS_UKD_STATUS_3_DP_PDOTPR_DECREE_14: 'EDI_FNS_UKD_STATUS_3_DP_PDOTPR_DECREE_14',
		EDI_FNS_UKD_STATUS_4_DP_PDOTPR_DECREE_14: 'EDI_FNS_UKD_STATUS_4_DP_PDOTPR_DECREE_14',
		EDI_FNS_UKD_STATUS_5_DP_IZVPOL_DECREE_14: 'EDI_FNS_UKD_STATUS_5_DP_IZVPOL_DECREE_14',
		EDI_FNS_UKD_STATUS_6_IOP_DP_PDPOL_DECREE_14: 'EDI_FNS_UKD_STATUS_6_IOP_DP_PDPOL_DECREE_14',
		EDI_FNS_UKD_STATUS_7_IOP_DP_PDOTPR_DECREE_14: 'EDI_FNS_UKD_STATUS_7_IOP_DP_PDOTPR_DECREE_14',
		EDI_FNS_UKD_STATUS_8_IOP_DP_PDOTPR_DECREE_14: 'EDI_FNS_UKD_STATUS_8_IOP_DP_PDOTPR_DECREE_14',
		EDI_FNS_UKD_STATUS_8_DP_PDOTPR_DECREE_14: 'EDI_FNS_UKD_STATUS_8_DP_PDOTPR_DECREE_14',
		EDI_FNS_UKD_STATUS_10_DP_PDOTPR_DECREE_14: 'EDI_FNS_UKD_STATUS_10_DP_PDOTPR_DECREE_14',

		EDI_FNS_UKD_STATUS_5_DP_UVUTOCH_DECREE_14: 'EDI_FNS_UKD_STATUS_5_DP_UVUTOCH_DECREE_14',
		EDI_FNS_UKD_STATUS_6_UOU_DP_PDPOL_DECREE_14: 'EDI_FNS_UKD_STATUS_6_UOU_DP_PDPOL_DECREE_14',
		EDI_FNS_UKD_STATUS_7_UOU_DP_PDOTPR_DECREE_14: 'EDI_FNS_UKD_STATUS_7_UOU_DP_PDOTPR_DECREE_14',
		EDI_FNS_UKD_STATUS_8_UOU_DP_PDOTPR_DECREE_14: 'EDI_FNS_UKD_STATUS_8_UOU_DP_PDOTPR_DECREE_14',

		EDI_FNS_UPD_SERVICE_STATUS_2_DP_PDPOL: 'EDI_FNS_UPD_SERVICE_STATUS_2_DP_PDPOL',
		EDI_FNS_UPD_SERVICE_STATUS_3_DP_PDOTPR: 'EDI_FNS_UPD_SERVICE_STATUS_3_DP_PDOTPR',
		EDI_FNS_UPD_SERVICE_STATUS_4_DP_IZVPOL: 'EDI_FNS_UPD_SERVICE_STATUS_4_DP_IZVPOL',
		EDI_FNS_UPD_SERVICE_STATUS_5_DP_IZVPOL: 'EDI_FNS_UPD_SERVICE_STATUS_5_DP_IZVPOL',
		EDI_FNS_UPD_SERVICE_STATUS_6_DP_IZVPOL: 'EDI_FNS_UPD_SERVICE_STATUS_6_DP_IZVPOL',
		EDI_FNS_UPD_SERVICE_STATUS_7_DP_PDOTPR: 'EDI_FNS_UPD_SERVICE_STATUS_7_DP_PDOTPR',
		EDI_FNS_UPD_SERVICE_STATUS_8_DP_IZVPOL: 'EDI_FNS_UPD_SERVICE_STATUS_8_DP_IZVPOL',
		EDI_FNS_UPD_SERVICE_STATUS_9_DP_UVUTOCH: 'EDI_FNS_UPD_SERVICE_STATUS_9_DP_UVUTOCH',
		EDI_FNS_UPD_SERVICE_STATUS_10_DP_IZVPOL: 'EDI_FNS_UPD_SERVICE_STATUS_10_DP_IZVPOL',

		EDI_FNS_DP_PRANNUL: 'EDI_FNS_DP_PRANNUL',
		EDI_FNS_DP_PRANNUL_UTOCH: 'EDI_FNS_DP_PRANNUL_UTOCH',
		EDI_FNS_ARCHIVE: 'EDI_FNS_ARCHIVE',
		EDI_FNS_UKD_ARCHIVE: 'EDI_FNS_UKD_ARCHIVE',
		SLSRPT: 'SLSRPT',
		DELFOR: 'DELFOR',
		PRICAT_EANCOM: 'PRICAT_EANCOM',
		EDI_PRICAT_EANCOM_DP_IZVPOL: 'EDI_PRICAT_EANCOM_DP_IZVPOL',
		EDI_CONTRL: 'EDI_CONTRL',
		EDI_FNS_UPD_SERVICE_AKT: 'EDI_FNS_UPD_SERVICE_AKT',
		EDI_FNS_UPD_SERVICE_STATUS_5_DP_IZVPOL_DECREE_14: 'EDI_FNS_UPD_SERVICE_STATUS_5_DP_IZVPOL_DECREE_14',
		EDI_SLSRPT: 'EDI_SLSRPT',
		EDI_FNS_TORG2: 'EDI_FNS_TORG2',
		EDI_FNS_TORG2_P1: 'EDI_FNS_TORG2_P1',
		EDI_FNS_TORG2_P2: 'EDI_FNS_TORG2_P2',
		EDI_FNS_TORG2_DP_UVUTOCH: 'EDI_FNS_TORG2_DP_UVUTOCH',
		EDI_FNS_TORG2_DP_IZVPOL: 'EDI_FNS_TORG2_DP_IZVPOL'
	},
	UPD_DOCUMENT_TYPES: ['INVOICE', 'INVOICEDOP', 'DOP'],
	DOCUMENT_TYPES_BY_NUMBERS: {
		27: 'FNS_INVOICE'
	},
	STATE: {
		OPERATOR_INVOICE_SENT_ACK_TO_SENDER_RECEIVE_ADVISE: 'OPERATOR_INVOICE_SENT_ACK_TO_SENDER_RECEIVE_ADVISE',
		OPERATOR_INVOICE_SENT_ACK_TO_RECEIVER_RECEIVE_ADVISE: 'OPERATOR_INVOICE_SENT_ACK_TO_RECEIVER_RECEIVE_ADVISE',
		RECEIVER_INVOICE_RECEIVED_ACK: 'RECEIVER_INVOICE_RECEIVED_ACK',
		SENDER_INVOICE_RECEIVED_ACK_ADVISE: 'SENDER_INVOICE_RECEIVED_ACK_ADVISE',
		OPERATOR_UPD_SENT_ACK_TO_SENDER_RECEIVE_ADVISE: 'OPERATOR_UPD_SENT_ACK_TO_SENDER_RECEIVE_ADVISE',
		OPERATOR_UPD_SERVICE_SENT_ACK_TO_SENDER_RECEIVE_ADVISE:
			'OPERATOR_UPD_SERVICE_SENT_ACK_TO_SENDER_RECEIVE_ADVISE',
		OPERATOR_UPD_SENT_ACK_TO_RECEIVER_RECEIVE_ADVISE: 'OPERATOR_UPD_SENT_ACK_TO_RECEIVER_RECEIVE_ADVISE',
		OPERATOR_UPD_SERVICE_SENT_ACK_TO_RECEIVER_RECEIVE_ADVISE:
			'OPERATOR_UPD_SERVICE_SENT_ACK_TO_RECEIVER_RECEIVE_ADVISE',
		RECEIVER_UPD_RECEIVED_ACK: 'RECEIVER_UPD_RECEIVED_ACK',
		RECEIVER_UPD_RECEIVED_ACK_DECREE_14: 'RECEIVER_UPD_RECEIVED_ACK_DECREE_14',
		RECEIVER_UPD_SERVICE_RECEIVED_ACK: 'RECEIVER_UPD_SERVICE_RECEIVED_ACK',
		SENDER_UPD_RECEIVED_ACK_ADVISE: 'SENDER_UPD_RECEIVED_ACK_ADVISE',
		SENDER_UPD_SERVICE_RECEIVED_ACK_ADVISE: 'SENDER_UPD_SERVICE_RECEIVED_ACK_ADVISE',
		OPERATOR_UKD_SENT_ACK_TO_SENDER_RECEIVE_ADVISE: 'OPERATOR_UKD_SENT_ACK_TO_SENDER_RECEIVE_ADVISE',
		OPERATOR_UKD_SENT_ACK_TO_RECEIVER_RECEIVE_ADVISE: 'OPERATOR_UKD_SENT_ACK_TO_RECEIVER_RECEIVE_ADVISE',
		RECEIVER_UKD_RECEIVED_ACK: 'RECEIVER_UKD_RECEIVED_ACK',
		RECEIVER_UKD_RECEIVED_ACK_DECREE_14: 'RECEIVER_UKD_RECEIVED_ACK_DECREE_14',
		SENDER_UKD_RECEIVED_ACK_ADVISE: 'SENDER_UKD_RECEIVED_ACK_ADVISE',
		WAIT_SIGNATURE_STATUS10: 'WAIT_SIGNATURE_STATUS10',
		PRICAT_EANCOM_DP_IZVPOL_RECEIVER: 'PRICAT_EANCOM_DP_IZVPOL_RECEIVER'
	}
});

edi.constants.ACTION_ON_DOCUMENTS_ADDED_DOCUMENT_TYPES = [
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_P1,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_P1
];

Ext.merge(edi.constants, {
	UPD_UKD_DOCUMENT_TYPES: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD, edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD]
});

edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD = {
	SCHFDOP_SCHFDOP: 'SCHFDOP_SCHFDOP_V2',
	SCHFDOP_SCHF: 'SCHFDOP_SCHF_V2',
	SCHFDOP_DOP: 'SCHFDOP_DOP_V2',
	SCHF_ROAMING_IN: 'FNS_UPD_SCHF_ROAMING_IN',
	SCHF_ROAMING_OUT: 'FNS_UPD_SCHF_ROAMING_OUT',
	DOP_ROAMING: 'FNS_UPD_DOP_ROAMING',
	SCHFDOP_ROAMING_IN: 'FNS_UPD_SCHF_DOP_ROAMING_IN',
	SCHFDOP_ROAMING_OUT: 'FNS_UPD_SCHF_DOP_ROAMING_OUT',
	DOP_ROAMING_OUT: 'FNS_UPD_DOP_ROAMING_OUT',
	SCHFDOP_SCHFDOP_DECREE_14: 'SCHFDOP_SCHFDOP_DECREE_14',
	SCHFDOP_SCHF_DECREE_14: 'SCHFDOP_SCHF_DECREE_14',
	SCHF_ROAMING_IN_DECREE_14: 'FNS_UPD_SCHF_ROAMING_IN_DECREE_14',
	SCHF_ROAMING_OUT_DECREE_14: 'FNS_UPD_SCHF_ROAMING_OUT_DECREE_14',
	DOP_ROAMING_DECREE_14: 'FNS_UPD_DOP_ROAMING_DECREE_14',
	SCHFDOP_ROAMING_IN_DECREE_14: 'FNS_UPD_SCHF_DOP_ROAMING_IN_DECREE_14',
	SCHFDOP_ROAMING_OUT_DECREE_14: 'FNS_UPD_SCHF_DOP_ROAMING_OUT_DECREE_14',
	DOP_ROAMING_OUT_DECREE_14: 'FNS_UPD_DOP_ROAMING_OUT_DECREE_14',
	SCHFDOP_SVRK_DECREE_14: 'SCHFDOP_SVRK_DECREE_14',
	SCHFDOP_SVZK_DECREE_14: 'SCHFDOP_SVZK_DECREE_14',
	//SCHF_DECREE_14_WITHOUT_OPERATOR: 'FNS_UPD_SCHF_DECREE_14_WITHOUT_OPERATOR',
	//SCHF_DOP_DECREE_14_WITHOUT_OPERATOR: 'FNS_UPD_SCHF_DOP_DECREE_14_WITHOUT_OPERATOR',
	UPD_DOP_BPV4: 'UPD_DOP_BPV4',
	UPD_SCHFDOP_BPV4: 'UPD_SCHFDOP_BPV4',
	UPD_DOP_ROAMING_IN_BPV4: 'UPD_DOP_ROAMING_IN_BPV4',
	UPD_DOP_ROAMING_OUT_BPV4: 'UPD_DOP_ROAMING_OUT_BPV4'
};

edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION = {
	UPD_DOP_502: 'UPD_DOP_502',
	UPD_SCHF_502: 'UPD_SCHF_502',
	UPD_SCHFDOP_502: 'UPD_SCHFDOP_502',
	UPD_SVRK_502: 'UPD_SVRK_502',
	UPD_SVZK_502: 'UPD_SVZK_502',
	UPD_DOP_503: 'UPD_DOP_503',
	UPD_SCHF_503: 'UPD_SCHF_503',
	UPD_SCHFDOP_503: 'UPD_SCHFDOP_503',
	UPD_SVRK_503: 'UPD_SVRK_503',
	UPD_SVZK_503: 'UPD_SVZK_503'
};

Ext.merge(edi.constants.TRANSFORMATIONS_DOC_FLOW_TYPE_KEY, {
	[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHFDOP]:
		'transformation.doc.flow.type.SCHFDOP_SCHFDOP_V2_501',
	[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHF]: 'transformation.doc.flow.type.SCHFDOP_SCHF_V2_501',
	[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_DOP]: 'transformation.doc.flow.type.SCHFDOP_DOP_V2_501',
	[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SVRK_DECREE_14]:
		'transformation.doc.flow.type.SCHFDOP_SVRK_DECREE_14_501',
	[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SVZK_DECREE_14]:
		'transformation.doc.flow.type.SCHFDOP_SVZK_DECREE_14_501',

	[edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_DOP_502]: 'transformation.doc.flow.type.UPD_DOP_502',
	[edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_SCHF_502]: 'transformation.doc.flow.type.UPD_SCHF_502',
	[edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_SCHFDOP_502]: 'transformation.doc.flow.type.UPD_SCHFDOP_502',
	[edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_SVRK_502]: 'transformation.doc.flow.type.UPD_SVRK_502',
	[edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_SVZK_502]: 'transformation.doc.flow.type.UPD_SVZK_502',

	[edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_DOP_503]: 'transformation.doc.flow.type.UPD_DOP_503',
	[edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_SCHF_503]: 'transformation.doc.flow.type.UPD_SCHF_503',
	[edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_SCHFDOP_503]: 'transformation.doc.flow.type.UPD_SCHFDOP_503',
	[edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_SVRK_503]: 'transformation.doc.flow.type.UPD_SVRK_503',
	[edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_SVZK_503]: 'transformation.doc.flow.type.UPD_SVZK_503'
});

//при создании происходит подмена параметра bpName по данной мапе, если значения нет создается с исходным
//исходные значение:
//edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHFDOP,
//edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHF,
//edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_DOP
edi.constants.FNS_BPNAMES_CREATE_MAP = {
	[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHFDOP_DECREE_14]:
		edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHFDOP,
	[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHF_DECREE_14]:
		edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHF,
	[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.UPD_DOP_ROAMING_OUT_BPV4]:
		edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_DOP,
	[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_ROAMING_OUT_DECREE_14]:
		edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHFDOP,
	[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.DOP_ROAMING_OUT_DECREE_14]:
		edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_DOP,
	[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHF_ROAMING_OUT_DECREE_14]:
		edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHF
};

edi.constants.documentsForVisibleTemplates = edi.constants.documentsForVisibleTemplates.concat([
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
	edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_INVOICE
]);

edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD = {
	SCHFDOP_KSCHFDIS: 'SCHFDOP_KSCHFDIS',
	SCHFDOP_KSCHF: 'SCHFDOP_KSCHF',
	SCHFDOP_DIS: 'SCHFDOP_DIS',
	DIS_ROAMING: 'FNS_UKD_DIS_ROAMING',
	KSCHF_ROAMING_IN: 'FNS_UKD_KSCHF_ROAMING_IN',
	KSCHF_ROAMING_OUT: 'FNS_UKD_KSCHF_ROAMING_OUT',
	KSCHF_DIS_ROAMING_IN: 'FNS_UKD_KSCHF_DIS_ROAMING_IN',
	KSCHF_DIS_ROAMING_OUT: 'FNS_UKD_KSCHF_DIS_ROAMING_OUT',
	DIS_ROAMING_OUT: 'FNS_UKD_DIS_ROAMING_OUT',
	FNS_UKD_KSCHF_DIS_DECREE_14: 'FNS_UKD_KSCHF_DIS_DECREE_14',
	FNS_UKD_KSCHF_DECREE_14: 'FNS_UKD_KSCHF_DECREE_14',
	FNS_UKD_DIS_DECREE_14: 'FNS_UKD_DIS_DECREE_14',
	FNS_UKD_DIS_ROAMING_DECREE_14: 'FNS_UKD_DIS_ROAMING_DECREE_14',
	FNS_UKD_KSCHF_ROAMING_IN_DECREE_14: 'FNS_UKD_KSCHF_ROAMING_IN_DECREE_14',
	FNS_UKD_KSCHF_ROAMING_OUT_DECREE_14: 'FNS_UKD_KSCHF_ROAMING_OUT_DECREE_14',
	FNS_UKD_KSCHF_DIS_ROAMING_IN_DECREE_14: 'FNS_UKD_KSCHF_DIS_ROAMING_IN_DECREE_14',
	FNS_UKD_KSCHF_DIS_ROAMING_OUT_DECREE_14: 'FNS_UKD_KSCHF_DIS_ROAMING_OUT_DECREE_14',
	FNS_UKD_DIS_ROAMING_OUT_DECREE_14: 'FNS_UKD_DIS_ROAMING_OUT_DECREE_14',
	FNS_UKD_SVISRK_DECREE_14: 'FNS_UKD_SVISRK_DECREE_14',
	FNS_UKD_SVISZK_DECREE_14: 'FNS_UKD_SVISZK_DECREE_14',
	UKD_DIS_BPV4: 'UKD_DIS_BPV4',
	UKD_KSCHFDIS_BPV4: 'UKD_KSCHFDIS_BPV4',
	UKD_DIS_ROAMING_OUT_BPV4: 'UKD_DIS_ROAMING_OUT_BPV4',
	UKD_DIS_ROAMING_IN_BPV4: 'UKD_DIS_ROAMING_IN_BPV4'
};
edi.constants.FNS_BPNAMES_CREATE_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_KSCHF_DECREE_14] =
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHFDOP_KSCHF;
edi.constants.FNS_BPNAMES_CREATE_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_KSCHF_DIS_DECREE_14] =
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHFDOP_KSCHFDIS;
edi.constants.FNS_BPNAMES_CREATE_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.UKD_DIS_ROAMING_OUT_BPV4] =
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHFDOP_DIS;
edi.constants.FNS_BPNAMES_CREATE_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_KSCHF_ROAMING_OUT_DECREE_14] =
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHFDOP_KSCHF;
edi.constants.FNS_BPNAMES_CREATE_MAP[
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_KSCHF_DIS_ROAMING_OUT_DECREE_14
] = edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHFDOP_KSCHFDIS;
edi.constants.FNS_BPNAMES_CREATE_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_DIS_ROAMING_OUT_DECREE_14] =
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHFDOP_DIS;

edi.constants.UKD_BP_NAMES_FOR_TRANSFORMATION = {
	UKD_DIS_501: 'UKD_DIS_501',
	UKD_KSCHF_501: 'UKD_KSCHF_501',
	UKD_KSCHF_DIS_501: 'UKD_KSCHF_DIS_501',
	UKD_DIS_501_N: 'UKD_DIS_501_N',
	UKD_KSCHF_501_N: 'UKD_KSCHF_501_N',
	UKD_KSCHF_DIS_501_N: 'UKD_KSCHF_DIS_501_N'
};

edi.constants.UPD_BP_NAMES_FOR_FILTER = [
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHF,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_DOP,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHFDOP,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SVRK_DECREE_14,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SVZK_DECREE_14
];

edi.constants.UKD_BP_NAMES_FOR_FILTER = [
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHFDOP_KSCHF,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHFDOP_DIS,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHFDOP_KSCHFDIS,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_SVISRK_DECREE_14,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_SVISZK_DECREE_14
];

edi.constants.USE_BEFORE_SIGN_DOCUMENT_TYPES.push(
	'DSF_EDI_FNS_ACT',
	'DSF_EDI_FNS_INVOICE',
	edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2,
	'EDI_FNS_UPD',
	'EDI_FNS_UKD',
	'EDI_FNS_UPD_SERVICE_AKT',
	'LOGSET',
	'DELFOR'
);

edi.constants.SIGN_DOCUMENT_TYPES.push(
	'DSF_EDI_FNS_ACT',
	'DSF_EDI_FNS_INVOICE',
	'EDI_FNS_UPD',
	'EDI_FNS_UKD',
	'EDI_FNS_TORG2',
	'EDI_FNS_UPD_SERVICE_AKT'
);

edi.constants.USE_BEFORE_SIGN_RULES.push({
	DOCUMENT_TYPES: [edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_ACT, edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_INVOICE],
	STATES: [edi.constants.STATE.SENT, edi.constants.STATE.WAIT_RECEIVER_DECISION]
});

edi.constants.FREE_DOCUMENT_TYPES.push('DSF_EDI_FNS_ACT', 'DSF_EDI_FNS_INVOICE');
edi.constants.FIELDS_TO_REMOVE_AFTER_MERGE_FNS = [
	'id',
	'header',
	'creationDate',
	'modifyDate',
	'fileInvoiceCreationDate',
	'fileInvoiceCreationTime'
];
edi.constants.MODULE_NAME.EDI = 'FNS';
edi.constants.NAVIGATION_NAME = 'fns';

edi.constants.UPD_PRICE_PRECISION = 2;
edi.constants.UKD_PRICE_PRECISION = 2;

edi.constants.BP_NAME_MAP = {};
edi.constants.BP_NAME_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHFDOP] =
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHFDOP_KSCHFDIS;
edi.constants.BP_NAME_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_DOP] =
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHFDOP_DIS;
edi.constants.BP_NAME_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHF] =
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHFDOP_KSCHF;
edi.constants.BP_NAME_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SVRK_DECREE_14] =
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_SVISRK_DECREE_14;
edi.constants.BP_NAME_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SVZK_DECREE_14] =
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_SVISZK_DECREE_14;

edi.constants.NOTICE_DOC_TYPES = [
	edi.constants.DOCUMENT_TYPES.EDI_FNS_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_DP_PDOTPR,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_2_DP_PDPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_3_DP_PDOTPR,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_4_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_4_P1_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_5_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_6_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_7_DP_PDOTPR,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_8_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_9_DP_UVUTOCH,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_9_DP_PDPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_10_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_11_P2_DP_IZVPOL,

	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_2_DP_PDPOL_DECREE_14,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_3_DP_PDOTPR_DECREE_14,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_4_DP_PDOTPR_DECREE_14,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_5_DP_IZVPOL_DECREE_14,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_6_IOP_DP_PDPOL_DECREE_14,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_6_UOU_DP_PDPOL_DECREE_14,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_7_IOP_DP_PDOTPR_DECREE_14,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_7_UOU_DP_PDOTPR_DECREE_14,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_8_IOP_DP_PDOTPR_DECREE_14,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_8_UOU_DP_PDOTPR_DECREE_14,

	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_STATUS_2_DP_PDPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_STATUS_3_DP_PDOTPR,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_STATUS_4_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_STATUS_5_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_STATUS_6_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_STATUS_7_DP_PDOTPR,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_STATUS_8_DP_IZVPOL,

	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_2_DP_PDPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_3_DP_PDOTPR,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_4_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_4_P1_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_5_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_6_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_7_DP_PDOTPR,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_8_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_9_DP_UVUTOCH,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_9_DP_PDPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_10_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_11_P2_DP_IZVPOL,

	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_2_DP_PDPOL_DECREE_14,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_3_DP_PDOTPR_DECREE_14,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_4_DP_PDOTPR_DECREE_14,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_5_DP_IZVPOL_DECREE_14,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_6_IOP_DP_PDPOL_DECREE_14,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_6_UOU_DP_PDPOL_DECREE_14,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_7_IOP_DP_PDOTPR_DECREE_14,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_7_UOU_DP_PDOTPR_DECREE_14,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_8_IOP_DP_PDOTPR_DECREE_14,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_8_UOU_DP_PDOTPR_DECREE_14,

	edi.constants.DOCUMENT_TYPES.EDI_PRICAT_EANCOM_DP_IZVPOL
];

edi.constants.DOCUMENT_TYPE_STATUS_MAP = {};
edi.constants.DOCUMENT_TYPE_STATUS_MAP[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD] =
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_9_DP_UVUTOCH;
edi.constants.DOCUMENT_TYPE_STATUS_MAP[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD] =
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_9_DP_UVUTOCH;

edi.constants.PARTS_DOC_TYPES[edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2] = {
	P1: edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2_P1,
	P2: edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2_P2
};

edi.constants.PARTS_DOC_TYPES[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD] = {
	P1: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_P1,
	P2: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_P2
};

edi.constants.PARTS_DOC_TYPES[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD] = {
	P1: edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_P1,
	P2: edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_P2
};

edi.constants.PARTS_DOC_TYPES[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT] = {
	P1: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT_P1,
	P2: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT_P2
};

edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_ACT] = 'document.create.dsf';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_INVOICE] = 'document.create.dsf';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD + '_5.01-N'] =
	'document.create.fns_upd_5_01n';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD + '_5.02-N'] =
	'document.create.fns_upd_5_02n';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD + '_5.03-N'] =
	'document.create.fns_upd_5_03n';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD + '_5.01-N'] =
	'document.create.fns_ukd_5_01n';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT] =
	'document.create.service_act';
edi.constants.CREATE_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2] = 'document.create.torg_2';

edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2] = 'document.details.torg_2';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_ACT] = 'document.details.dsf';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_INVOICE] = 'document.details.dsf';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD] = 'document.details.fns_upd';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD + '_5.01'] =
	'document.details.fns_upd';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD + '_5.01-N'] =
	'document.details.fns_upd_5_01n';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD + '_5.02-N'] =
	'document.details.fns_upd_5_02n';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD + '_5.03-N'] =
	'document.details.fns_upd_5_03n';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD + '_5.02'] =
	'document.details.fns_upd_5_02';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD] = 'document.details.fns_ukd';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD + '_5.01'] =
	'document.details.fns_ukd';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD + '_5.02'] =
	'document.details.fns_ukd';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD + '_5.01-N'] =
	'document.details.fns_ukd_5_01n';
edi.constants.DETAILS_MODULE_NAME_BY_TYPE[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT] =
	'document.details.service_act';

edi.constants.MODULES.DOCUMENTS.COPY_USING_UI.push(
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD,
	edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_ACT,
	edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_INVOICE
);

edi.constants.MODULES.DOCUMENTS.INCLUDED_TYPES.push(
	edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_ACT,
	edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_INVOICE,
	edi.constants.DOCUMENT_TYPES.LEGACY_REC_ADV,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD,
	edi.constants.DOCUMENT_TYPES.PRICAT_EANCOM,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT
);

edi.constants.MODULES.APERAK_DOCUMENT_TYPES.push(
	edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_ACT,
	edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_INVOICE,
	edi.constants.DOCUMENT_TYPES.LEGACY_REC_ADV,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_ARCHIVE,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_ARCHIVE
);

edi.constants.SIGNATURE_SIDE.INCOMING.CONSUMER.push(
	edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_ACT,
	edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_INVOICE,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT
);

edi.constants.SIGNATURE_SIDE.OUTGOING.SUPPLIER.push(
	edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_ACT,
	edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_INVOICE,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT
);

edi.constants.WEB_UPLOAD_DOCTYPES.push(
	{
		docType: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_P1,
		version: '5.01-N',
		permissions: ['CLIENT_EDI_FNS_UPD_5_01_N_CREATE']
	},
	{
		docType: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_P1,
		version: '5.02-N',
		permissions: ['CLIENT_EDI_FNS_UPD_5_02_N_CREATE']
	},
	{
		docType: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_P1,
		version: '5.03-N'
	}
);

edi.constants.ACTUAL_FREE_DOCUMENT_TYPES = edi.constants.ACTUAL_FREE_DOCUMENT_TYPES.concat([
	edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_ACT,
	edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_INVOICE
]);

edi.constants.CREATE_ACTUAL_FREE_DOCUMENT_TYPES = edi.constants.CREATE_ACTUAL_FREE_DOCUMENT_TYPES.concat([
	edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_ACT,
	edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_INVOICE
]);

edi.constants.NOT_ACTUAL_FREE_DOCUMENT_TYPES = Ext.Array.difference(
	edi.constants.FREE_DOCUMENT_TYPES,
	edi.constants.ACTUAL_FREE_DOCUMENT_TYPES
);

edi.constants.DOCUMENT_TYPES_FROM_TRANSFORMATIONS[edi.constants.DOCUMENT_TYPES.LEGACY_ORDER] = [].concat(
	edi.constants.DOCUMENT_TYPES_FROM_TRANSFORMATIONS[edi.constants.DOCUMENT_TYPES.LEGACY_ORDER],
	[
		edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHFDOP,
		edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHF,
		edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_DOP,
		edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SVRK_DECREE_14,
		edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SVZK_DECREE_14,

		edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_DOP_502,
		edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_SCHF_502,
		edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_SCHFDOP_502,
		edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_SVRK_502,
		edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_SVZK_502,

		edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_DOP_503,
		edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_SCHF_503,
		edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_SCHFDOP_503,
		edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_SVRK_503,
		edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_SVZK_503
	]
);

edi.constants.DOCUMENT_TYPES_FROM_TRANSFORMATIONS[edi.constants.DOCUMENT_TYPES.LEGACY_DES_ADV] = [].concat(
	edi.constants.DOCUMENT_TYPES_FROM_TRANSFORMATIONS[edi.constants.DOCUMENT_TYPES.LEGACY_DES_ADV],
	[
		edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHFDOP,
		edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHF,
		edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_DOP,
		edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SVRK_DECREE_14,
		edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SVZK_DECREE_14,

		edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_DOP_502,
		edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_SCHF_502,
		edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_SCHFDOP_502,
		edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_SVRK_502,
		edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_SVZK_502,

		edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_DOP_503,
		edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_SCHF_503,
		edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_SCHFDOP_503,
		edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_SVRK_503,
		edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_SVZK_503
	]
);

edi.constants.DOCUMENT_TYPES_FROM_TRANSFORMATIONS[edi.constants.DOCUMENT_TYPES.LEGACY_REC_ADV] = [].concat(
	edi.constants.DOCUMENT_TYPES_FROM_TRANSFORMATIONS[edi.constants.DOCUMENT_TYPES.LEGACY_REC_ADV],
	[
		edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHFDOP,
		edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHF,
		edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_DOP,
		edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SVRK_DECREE_14,
		edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SVZK_DECREE_14,

		edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_DOP_502,
		edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_SCHF_502,
		edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_SCHFDOP_502,
		edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_SVRK_502,
		edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_SVZK_502,

		edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_DOP_503,
		edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_SCHF_503,
		edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_SCHFDOP_503,
		edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_SVRK_503,
		edi.constants.UPD_BP_NAMES_FOR_TRANSFORMATION.UPD_SVZK_503
	]
);

edi.constants.DOCUMENT_TYPES_FROM_TRANSFORMATIONS[edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2_P1] = [
	edi.constants.UKD_BP_NAMES_FOR_TRANSFORMATION.UKD_DIS_501_N,
	edi.constants.UKD_BP_NAMES_FOR_TRANSFORMATION.UKD_KSCHF_DIS_501_N
];

edi.constants.DOCUMENT_TYPES_FROM_TRANSFORMATIONS[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_P1] = [
	edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2_P1
];

edi.constants.DOCUMENT_TYPES_FROM_TRANSFORMATIONS[edi.constants.DOCUMENT_TYPES.LEGACY_RETANN] = [
	edi.constants.UKD_BP_NAMES_FOR_TRANSFORMATION.UKD_DIS_501,
	edi.constants.UKD_BP_NAMES_FOR_TRANSFORMATION.UKD_KSCHF_501,
	edi.constants.UKD_BP_NAMES_FOR_TRANSFORMATION.UKD_KSCHF_DIS_501,
	edi.constants.UKD_BP_NAMES_FOR_TRANSFORMATION.UKD_DIS_501_N,
	edi.constants.UKD_BP_NAMES_FOR_TRANSFORMATION.UKD_KSCHF_501_N,
	edi.constants.UKD_BP_NAMES_FOR_TRANSFORMATION.UKD_KSCHF_DIS_501_N
];

edi.constants.DOCUMENT_TYPES_FROM_TRANSFORMATIONS[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_P1] = [].concat(
	edi.constants.DOCUMENT_TYPES_FROM_TRANSFORMATIONS[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_P1],
	['CORRECTION_UKD']
);

edi.constants.CREATE_PART2_BY_ACTION = edi.constants.CREATE_PART2_BY_ACTION.concat([
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2
]);

edi.constants.RECEIPTS.push(
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_2_DP_PDPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_2_DP_PDPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_3_DP_PDOTPR,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_3_DP_PDOTPR,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_4_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_4_P1_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_4_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_4_P1_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_5_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_5_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_6_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_6_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_7_DP_PDOTPR,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_7_DP_PDOTPR,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_8_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_8_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_9_DP_UVUTOCH,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_9_DP_PDPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_9_DP_UVUTOCH,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_9_DP_PDPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_10_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_11_P2_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_10_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_11_P2_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_STATUS_2_DP_PDPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_STATUS_3_DP_PDOTPR,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_STATUS_4_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_STATUS_5_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_STATUS_6_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_STATUS_7_DP_PDOTPR,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_STATUS_8_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_DP_PRANNUL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_DP_PRANNUL_UTOCH,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2_DP_UVUTOCH,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2_DP_IZVPOL,

	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_2_DP_PDPOL_DECREE_14,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_3_DP_PDOTPR_DECREE_14,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_4_DP_PDOTPR_DECREE_14,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_5_DP_IZVPOL_DECREE_14,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_6_IOP_DP_PDPOL_DECREE_14,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_7_IOP_DP_PDOTPR_DECREE_14,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_8_IOP_DP_PDOTPR_DECREE_14,

	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_5_DP_UVUTOCH_DECREE_14,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_6_UOU_DP_PDPOL_DECREE_14,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_7_UOU_DP_PDOTPR_DECREE_14,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_8_UOU_DP_PDOTPR_DECREE_14,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_10_DP_PDOTPR_DECREE_14,

	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_2_DP_PDPOL_DECREE_14,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_3_DP_PDOTPR_DECREE_14,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_4_DP_PDOTPR_DECREE_14,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_5_DP_IZVPOL_DECREE_14,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_6_IOP_DP_PDPOL_DECREE_14,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_7_IOP_DP_PDOTPR_DECREE_14,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_8_IOP_DP_PDOTPR_DECREE_14,

	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_5_DP_UVUTOCH_DECREE_14,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_6_UOU_DP_PDPOL_DECREE_14,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_7_UOU_DP_PDOTPR_DECREE_14,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_8_UOU_DP_PDOTPR_DECREE_14,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_10_DP_PDOTPR_DECREE_14
);

edi.constants.HAS_PARENT_DOC_TYPES.push(
	...edi.constants.RECEIPTS,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_STATUS_2_DP_PDPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_STATUS_3_DP_PDOTPR,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_STATUS_4_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_STATUS_5_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_STATUS_6_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_STATUS_7_DP_PDOTPR,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_STATUS_8_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_STATUS_9_DP_UVUTOCH,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_STATUS_10_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_P1,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_P2,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_P1,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_P2,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT_P1,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT_P2
);

edi.constants.PARENT_DOC_TYPES_BY_CHILDREN[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD] = [
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_2_DP_PDPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_3_DP_PDOTPR,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_4_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_5_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_6_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_7_DP_PDOTPR,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_8_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_9_DP_UVUTOCH,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_10_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_P1,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_P2
];
edi.constants.PARENT_DOC_TYPES_BY_CHILDREN[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD] = [
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_2_DP_PDPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_3_DP_PDOTPR,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_4_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_5_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_6_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_7_DP_PDOTPR,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_8_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_9_DP_UVUTOCH,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_10_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_P1,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_P2
];
edi.constants.PARENT_DOC_TYPES_BY_CHILDREN[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT] = [
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT_P1,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT_P2,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_STATUS_2_DP_PDPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_STATUS_3_DP_PDOTPR,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_STATUS_4_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_STATUS_5_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_STATUS_6_DP_IZVPOL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_STATUS_7_DP_PDOTPR,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_STATUS_8_DP_IZVPOL
];

edi.constants.PRODUCT_TOTALS.USE_DOCDATA['set_rows'].push(
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT
);
edi.constants.PRODUCT_TOTALS.USE_DOCDATA['load_rows'].push(
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT
);

edi.constants.BEFORE_SIGN_PARENT_DOC.push(edi.constants.DOCUMENT_TYPES.EDI_PRICAT_EANCOM_DP_IZVPOL);

edi.constants.FNS_BPNAMES_PERMISSIONS_MAP = {};

edi.constants.FNS_BPNAMES_PERMISSION_PREFIX = {
	SCHFDOP: 'SCHFDOP',
	SCHF: 'SCHF',
	DOP: 'DOP',
	KSCHF: 'KSCHF',
	KSCHF_DIS: 'KSCHF_DIS',
	DIS: 'DIS'
};

edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHF] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.SCHF;
edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHF_ROAMING_OUT] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.SCHF;
edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHF_ROAMING_IN] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.SCHF;
edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHFDOP] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.SCHFDOP;
edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_ROAMING_IN] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.SCHFDOP;
edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_ROAMING_OUT] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.SCHFDOP;
edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_DOP] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.DOP;
edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.DOP_ROAMING] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.DOP;
edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.DOP_ROAMING_OUT] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.DOP;

edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHFDOP_DECREE_14] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.SCHFDOP;
edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHF_DECREE_14] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.SCHF;
edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHF_ROAMING_OUT_DECREE_14] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.SCHF;
edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHF_ROAMING_IN_DECREE_14] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.SCHF;
edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_ROAMING_IN_DECREE_14] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.SCHFDOP;
edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_ROAMING_OUT_DECREE_14] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.SCHFDOP;
edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.DOP_ROAMING_DECREE_14] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.DOP;
edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.DOP_ROAMING_OUT_DECREE_14] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.DOP;

edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SVRK_DECREE_14] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.SCHF;
edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SVZK_DECREE_14] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.SCHF;

edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.UPD_DOP_BPV4] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.DOP;
edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.UPD_SCHFDOP_BPV4] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.SCHFDOP;
edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.UPD_DOP_ROAMING_IN_BPV4] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.DOP;
edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.UPD_DOP_ROAMING_OUT_BPV4] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.DOP;

edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHFDOP_KSCHF] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.KSCHF;
edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.KSCHF_DIS_ROAMING_IN] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.KSCHF;
edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.KSCHF_DIS_ROAMING_OUT] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.KSCHF;
edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHFDOP_DIS] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.DIS;
edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.DIS_ROAMING] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.DIS;
edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHFDOP_KSCHFDIS] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.KSCHF_DIS;
edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.KSCHF_ROAMING_IN] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.KSCHF;
edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.KSCHF_ROAMING_OUT] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.KSCHF;
edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.KSCHF_DIS_ROAMING_OUT] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.KSCHF_DIS;
edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.KSCHF_DIS_ROAMING_IN] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.KSCHF_DIS;
edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.DIS_ROAMING_OUT] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.DIS;

edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_KSCHF_DIS_DECREE_14] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.KSCHF_DIS;
edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_KSCHF_DECREE_14] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.KSCHF;
edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_DIS_DECREE_14] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.DIS;
edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_DIS_ROAMING_DECREE_14] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.DIS;
edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_KSCHF_ROAMING_IN_DECREE_14
] = edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.KSCHF;
edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_KSCHF_ROAMING_OUT_DECREE_14
] = edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.KSCHF;
edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_KSCHF_DIS_ROAMING_OUT_DECREE_14
] = edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.KSCHF_DIS;
edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_KSCHF_DIS_ROAMING_IN_DECREE_14
] = edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.KSCHF_DIS;
edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_DIS_ROAMING_OUT_DECREE_14
] = edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.DIS;

edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_SVISRK_DECREE_14] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.KSCHF;
edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_SVISZK_DECREE_14] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.KSCHF;

edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.UKD_DIS_BPV4] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.DIS;
edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.UKD_KSCHFDIS_BPV4] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.KSCHF_DIS;
edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.UKD_DIS_ROAMING_OUT_BPV4] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.DIS;
edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.UKD_DIS_ROAMING_IN_BPV4] =
	edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.DIS;

edi.constants.FNS_UKD_IDENTIFY_PRODUCT_TOOL_TYPE = {
	SSCC: 'sscc',
	CIS: 'cis'
};

edi.constants.EMAIL_NOTIFICATION_DOC_TYPES = edi.constants.EMAIL_NOTIFICATION_DOC_TYPES.concat([
	edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2,
	edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_ACT,
	edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_INVOICE,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD
]);

Ext.merge(edi.constants, {
	DOCUMENT_NOT_BP: [edi.constants.DOCUMENT_TYPES.EDI_CONTRL]
});

edi.constants.REJECT_DSFV3.push(
	edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_ACT,
	edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_INVOICE
);

edi.constants.DSF_FOR_MULTY_SIGN = edi.constants.DSF_FOR_MULTY_SIGN.concat([
	edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_INVOICE,
	edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_ACT
]);

// области полномочий и статусы подписания для различных типов документов
edi.constants.SIGN_DOCUMENTS = {};

// Области полномочий в зависимости от типа документа и направления (титула)
edi.constants.SIGN_DOCUMENTS.AUTHORITY_AREAS_FOR_DOC_TYPES = {
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2]: {
		INBOX: [21, 22, 23, 29],
		OUTBOX: [1, 2, 3, 7, 8]
	},
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD]: {
		INBOX: [1, 2, 3],
		OUTBOX: [0, 1, 2, 3, 4, 5, 6]
	},
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD]: {
		INBOX: [0, 3, 6, 29],
		OUTBOX: [0, 3, 6]
	},
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT]: {
		INBOX: [1, 2, 3],
		OUTBOX: [0, 1, 2, 3, 4, 5, 6]
	}
};

// для входящих (титул 2) УКД 5.01 и 5.02 (устаревших)
edi.constants.SIGN_DOCUMENTS.AUTHORITY_AREAS_FOR_OBSOLETE_UKDS = {
	INBOX: [3]
};

// Статусы подписантов в зависимости от типа документа и направления (титула)
edi.constants.SIGN_DOCUMENTS.STATUSES_FOR_DOC_TYPES = {
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD]: {
		INBOX: [3, 4, 5, 6],
		OUTBOX: [1, 2, 3, 4]
	},
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2]: {
		INBOX: [1, 3, 4, 5, 6],
		OUTBOX: [3, 4, 5, 6]
	},
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD]: {
		INBOX: [3, 4, 5, 6],
		OUTBOX: [1, 2, 3, 4]
	},
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT]: {
		INBOX: [1, 2, 3, 4],
		OUTBOX: [1, 2, 3, 4]
	}
};

// для устаревших УКД (5.01 и 5.02)
edi.constants.SIGN_DOCUMENTS.STATUSES_FOR_OBSOLETE_UKDS = {
	INBOX: [1, 2, 3, 4]
};

Object.assign(Ext.form.field.VTypes, edi.methods.validators.createNumberValidator('fnsNumberN26_11', 26, 11));
Object.assign(Ext.form.field.VTypes, edi.methods.validators.createNumberValidator('fnsNumberN19_2', 19, 2));

edi.constants.TRANSFORMATIONS_KEY_UPD = edi.constants.TRANSFORMATIONS_KEY_UPD.concat([
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHFDOP,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHF,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_DOP,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SVRK_DECREE_14,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SVZK_DECREE_14
]);

edi.constants.EXLUDE_XLS_PRINT_FORMATS_DOC_TYPES.push(
	edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_ACT,
	edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_INVOICE
);

//Группы пользователей, неактуальные типы документов
edi.constants.USER_GROUP_IRRELEVANT_DOC_TYPES = [
	edi.constants.DOCUMENT_TYPES.DSF_RECADV_DIFF_ACT,
	edi.constants.DOCUMENT_TYPES.DSF_ACT_DIFFRNT,
	edi.constants.DOCUMENT_TYPES.LEGACY_DELJIT,
	edi.constants.DOCUMENT_TYPES.DSF_AGREEMENT,
	edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_ACT,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_CP,
	//edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_FP, // EW override
	//edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_FP02, // EW override
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_FP12,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_FP14,
	edi.constants.DOCUMENT_TYPES.EDI_IFTMBF,
	edi.constants.DOCUMENT_TYPES.EDI_IFTMBC,
	edi.constants.DOCUMENT_TYPES.EDI_SLSRPT,
	edi.constants.DOCUMENT_TYPES.EDI_INVRPT,
	edi.constants.DOCUMENT_TYPES.EDI_SLSFCT,
	edi.constants.DOCUMENT_TYPES.SLSRPT
];

DOCS_WITH_BUSINESS_STATE.push(
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2,
	edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_ACT,
	edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_INVOICE
);

edi.constants.ORGANIZATION_NOTIFICATION_DOC_TYPES = edi.constants.ORGANIZATION_NOTIFICATION_DOC_TYPES.concat([
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
	edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_INVOICE,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_DP_PRANNUL,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_INVOICE
]);

edi.constants.DOCS_WITH_ANNUL_LOG.push(
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT,
	edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_INVOICE
);

/**
 * При добавлении в эту константу не забывать вносить логику отправки с сам документ
 */
edi.constants.DOCS_WITH_BLOCK = edi.constants.DOCS_WITH_BLOCK.concat([
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
	edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD
]);

edi.constants.DOCUMENT_PART_2_FORM = [];
edi.constants.ACTIVE_EDI_FNS_UPD_VERSIONS = ['5.01-N', '5.02-N', '5.03-N'];
edi.constants.DOCUMENTS_SKIP_SAVE_AUTHORITY_CONFIRM = [
	{
		type: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
		version: '5.02-N'
	},
	{
		type: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
		version: '5.03-N'
	}
];
