// @ts-ignore
import { createDateRangeField } from '@UIkit/components/fields';
import { createPanel } from '@Components/panels';
import { filterMethods as documentsFilterMethods } from '@Edi/modules/documentGrids/filter';
import { createDocumentTypeFilterSelectForGrid } from '@Edi/specialComponents/documentTypeFilterSelect';
import { documentPackageMethods } from '@Edi/modules/documentPackages/methods';
import { createCombo, createSearchTypeSelectionFieldForGrid } from '@Components/fields';

export const filterMethods = {
	createPackagesFormItems: function (): { formItemsMap: { [key: string]: ExtComponent }; items: ExtComponent[] } {
		const states = [...new Set(Object.values(documentPackageMethods.PACKAGE_STATES_FOR_RENDERER))].map((state) => ({
			id: state,
			name: edi.i18n.getMessage(`documents.status.${state}`)
		}));

		const formItemsMap = {
			number: createSearchTypeSelectionFieldForGrid(
				{
					fieldLabel: edi.i18n.getMessage('document.form.number'),
					fieldConf: {
						useFieldLable: edi.i18n.getMessage('documents.list.document.number'),
						name: 'number'
					}
				},
				undefined,
				true
			),
			partner: createCombo({
				fieldLabel: edi.i18n.getMessage('documents.form.partner'),
				emptyText: '',
				store: edi.stores.initRelatedOrganizationsFirstEmptyStore(),
				anyMatch: true,
				autoValue: true,
				name: 'partner',
				showQtips: true
			}),
			state: createCombo({
				fieldLabel: edi.i18n.getMessage('documents.column.status'),
				emptyText: '',
				name: 'includeStates',
				store: edi.stores.createInlineStore(states, 'SIMPLE', undefined, {
					addEmptyRecord: true
				}),
				multiSelect: true,
				setLastValueAfterTextClear: true,
				anyMatch: true
			}),
			direction: createCombo({
				fieldLabel: edi.i18n.getMessage('documents.column.direction'),
				emptyText: '',
				name: 'direction',
				store: edi.stores.createInlineStore(
					[edi.constants.DIRECTIONS.OUTGOING, edi.constants.DIRECTIONS.INCOMING].map((dir) => ({
						id: dir,
						name: edi.i18n.getMessage(`documents.direction.${dir.toLowerCase()}`)
					})),
					'SIMPLE',
					undefined,
					{
						addEmptyRecord: true
					}
				)
			}),
			[edi.constants.DEFAULT.FILTER.FIELDS.CREATION_DATE]: createDateRangeField({
				chipsModalTitle: edi.i18n.getMessage('document.date.create'),
				chipTitle: edi.i18n.getMessage('document.date.create'),
				fieldsConfig: {
					common: {
						emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy')
					},
					from: {
						fieldLabel: edi.i18n.getMessage('document.date.create.from'),
						name: 'creationDateFrom'
					},
					to: {
						name: 'creationDateTo'
					}
				}
			}),
			[edi.constants.DEFAULT.FILTER.FIELDS.MODIFY_DATE]: createDateRangeField({
				chipsModalTitle: edi.i18n.getMessage('document.date.modify'),
				chipTitle: edi.i18n.getMessage('document.date.modify'),
				fieldsConfig: {
					common: {
						emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy')
					},
					from: {
						fieldLabel: edi.i18n.getMessage('document.modify.date.from')
					},
					to: {}
				}
			})
		};
		const items = [
			createPanel({
				layout: {
					type: 'grid',
					area: [
						[3, 3, 3, 3],
						[4, 4]
					]
				},
				items: [
					formItemsMap.number,
					formItemsMap.state,
					formItemsMap.partner,
					formItemsMap.direction,
					formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.CREATION_DATE],
					formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.MODIFY_DATE]
				]
			}) as ExtComponent
		];
		return {
			formItemsMap,
			items
		};
	},
	createArgsPackages: function (values: AnyObject): AnyObject {
		if (values.dateFrom) {
			values.dateFrom = edi.utils.getSearchTime(values.dateFrom, edi.constants.DATE_FORMAT.SERVER);
		}
		if (values.dateTo) {
			values.dateTo = edi.utils.getSearchTime(
				values.dateTo,
				edi.constants.DATE_FORMAT.SERVER,
				edi.constants.SEARCH_DAY_IN_MS
			);
		}
		if (values.creationDateFrom) {
			values.creationDateFrom = edi.utils.getSearchTime(
				values.creationDateFrom,
				edi.constants.DATE_FORMAT.SERVER
			);
		}
		if (values.creationDateTo) {
			values.creationDateTo = edi.utils.getSearchTime(
				values.creationDateTo,
				edi.constants.DATE_FORMAT.SERVER,
				edi.constants.SEARCH_DAY_IN_MS
			);
		}

		if (values.includeStates?.length) {
			values['includeStates'] = Ext.encode(values.includeStates.map((state: string) => ({ state })));
		}

		values = edi.filters.config.generic.convertFieldName(values, 'number');
		if (!Ext.isEmpty(values['number'])) {
			let numbers = Array.isArray(values['number']) ? values['number'] : values['number'].split(',');
			values['includeNumbers'] = Ext.encode(numbers);
			delete values['number'];
		}

		return values;
	},
	getExistingDocsTypes: function (): AnyObject {
		return {
			DSF: {
				order: 10,
				items: [
					edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED,
					edi.constants.DOCUMENT_TYPES.DSF_UNKNOWN,
					edi.constants.DOCUMENT_TYPES.DSF_INVOICE,
					edi.constants.DOCUMENT_TYPES.DSF_PRICAT,
					edi.constants.DOCUMENT_TYPES.DSF_SUPPLEMENTARY_AGREEMENT
				]
			}
		};
	},
	createAddExistingDocFormItems: function (): {
		formItemsMap: { [key: string]: ExtComponent };
		items: ExtComponent[];
	} {
		const { formItemsMap } = documentsFilterMethods.createCommonFormItems() as unknown as {
			formItemsMap: { [key: string]: ExtComponent };
			items: ExtComponent[];
		};

		const filterFormItems: { [key: string]: ExtComponent } = {
			[edi.constants.DEFAULT.FILTER.FIELDS.DOC_DATE]: formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.DOC_DATE],
			number: formItemsMap['number'],
			docType: createDocumentTypeFilterSelectForGrid({
				itemsTree: filterMethods.getExistingDocsTypes()
			}) as ExtComponent,
			[edi.constants.DEFAULT.FILTER.FIELDS.CREATION_DATE]:
				formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.CREATION_DATE],
			[edi.constants.DEFAULT.FILTER.FIELDS.MODIFY_DATE]:
				formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.MODIFY_DATE],
			orderNumber: formItemsMap['orderNumber'],
			desadvNumber: formItemsMap['desadvNumber'],
			contractNumber: formItemsMap['contractNumber']
		};

		filterFormItems[edi.constants.DEFAULT.FILTER.FIELDS.CREATION_DATE].hideFromPanel = true;
		filterFormItems[edi.constants.DEFAULT.FILTER.FIELDS.MODIFY_DATE].hideFromPanel = true;
		filterFormItems.orderNumber.hideFromPanel = true;
		filterFormItems.desadvNumber.hideFromPanel = true;
		filterFormItems.contractNumber.hideFromPanel = true;

		const filterItems: ExtComponent[] = [
			createPanel({
				layout: {
					type: 'grid',
					area: [
						[5, 7],
						[6, 6]
					]
				},
				items: [
					filterFormItems[edi.constants.DEFAULT.FILTER.FIELDS.DOC_DATE],
					filterFormItems.number,
					filterFormItems.docType,
					filterFormItems[edi.constants.DEFAULT.FILTER.FIELDS.CREATION_DATE],
					filterFormItems[edi.constants.DEFAULT.FILTER.FIELDS.MODIFY_DATE],
					filterFormItems.orderNumber,
					filterFormItems.desadvNumber,
					filterFormItems.contractNumber
				]
			}) as ExtComponent
		];

		return {
			formItemsMap: filterFormItems,
			items: filterItems
		};
	},
	createArgsAddExistingDoc: function (values: AnyObject, gridFilter: AnyObject, partner: number): AnyObject {
		values.includeBusinessStates = [edi.constants.STATE.DRAFT];
		if (!Array.isArray(values.includeDocTypes) || values.includeDocTypes.length === 0) {
			values.includeDocTypes = [...documentPackageMethods.ALLOWED_DOCUMENT_TYPES];
		}
		const args = documentsFilterMethods.createArgsCommon(values);
		args.partner = partner;
		args.packageId = null;
		return args;
	}
};
