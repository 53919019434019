/**
 * Created by Дарья on 21.02.2017.
 */

Ext.namespace('edi.models');

edi.models.setModels({
	CLIENT_NOTIFICATION: {
		fields: [
			{
				name: 'id',
				type: 'number'
			},
			{
				name: 'state',
				mapping: 'info',
				type: 'string'
			},
			{
				name: 'type',
				mapping: 'objectType',
				type: 'string'
			},
			{
				name: 'objectId',
				type: 'number'
			},
			{
				name: 'creationDate',
				type: 'number'
			},
			{
				name: 'action'
				//type: 'object'
			},
			{
				name: 'attributes'
				//type: "object"
			},
			{
				name: 'actionText',
				type: 'string',
				convert: function (v, record) {
					var action = record.get('action'),
						text = '';
					if (action.actionId) {
						text = edi.i18n.getMessage(
							action.actionId.replace('com.edisoft.notification', 'notification.action')
						);
					}
					return text;
				}
			},
			{
				name: 'fromOrg',
				//type: "object",
				convert: function (v, record) {
					var id = edi.utils.getAttributeByName(record.get('attributes'), 'FROM_ORG'),
						org;
					if (id) {
						org = edi.utils.getOrg({
							orgId: id
						});
					}
					return org || {};
				}
			},
			{
				name: 'toOrg',
				//type: "object",
				convert: function (v, record) {
					var id = edi.utils.getAttributeByName(record.get('attributes'), 'TO_ORG'),
						org;
					if (id) {
						org = edi.utils.getOrg({
							orgId: id
						});
					}
					return org || {};
				}
			},
			{
				name: 'doctime',
				mapping: 'docTime',
				type: 'number'
			},
			{
				name: 'number',
				mapping: 'docNumber',
				type: 'string'
			},
			{
				name: 'fromOrgName',
				mapping: 'fromOrgName',
				type: 'string'
			},
			{
				name: 'versionId',
				mapping: 'versionId',
				type: 'string'
			}
		]
	},
	USER_EMAIL_NOTIFICATION: {
		fields: [
			{
				name: 'id',
				type: 'number'
			},
			{
				name: 'allPartners',
				type: 'boolean'
			},
			{
				name: 'allOutgoingDocTypes',
				type: 'boolean'
			},
			{
				name: 'allIncomingDocTypes',
				type: 'boolean'
			},
			{
				name: 'creationDate',
				type: 'number'
			},
			{
				name: 'docTypeDirections'
			},
			{
				name: 'modifyDate',
				type: 'number'
			},
			{
				name: 'modifyDate',
				type: 'number'
			},
			{
				name: 'notificationEmails'
			},
			{
				name: 'org'
			},
			{
				name: 'orgPartners'
			}
		]
	},
	POSITION_SHORT_NAME: {
		extend: 'edi.models.SIMPLE',
		fields: [
			{
				name: 'shortName',
				type: 'string'
			}
		]
	},
	AUTHORITY_AREA: {
		extend: 'edi.models.SIMPLE',
		fields: [
			{
				name: 'name',
				type: 'string',
				convert: function (value, record) {
					//если уже добавлено record.get('id') + ' - ' не добавлять снова
					let prefix = record.get('id') + ' - ';
					return value.indexOf(prefix) > -1 ? value : prefix + value;
				}
			}
		]
	},
	VAT: {
		fields: [
			{
				name: 'vat',
				type: 'string'
			},
			{
				name: 'value',
				type: 'string'
			}
		],
		idProperty: 'value'
	},
	OKEI_CODES: {
		fields: [
			{
				name: 'id',
				type: 'string'
			},
			{
				name: 'name',
				type: 'string'
			},
			{
				name: 'name_international',
				type: 'string',
				convert: function (v, record) {
					if (!v) {
						return record.get('name_national_code');
					}
					return v;
				}
			},
			{
				name: 'name_national_code',
				type: 'string'
			},
			{
				name: 'name_code',
				type: 'string',
				convert: function (v, record) {
					let name = record.get('name') ? ` - ${record.get('name')}` : '';
					return record.get('id') + name;
				}
			}
		]
	},
	QUICK_ANSWER_SETTINGS: {
		idProperty: 'partnerId',
		fields: [
			{
				name: 'partnerId',
				type: 'number'
			},
			{
				name: 'docTypes'
				//type: 'array'
			},
			{
				name: 'docTypesCode',
				//type: 'object',
				convert: function (v, record) {
					var types = {},
						value = record.get('docTypes');
					value.map(function (item) {
						if (!types.hasOwnProperty(item.nameType)) {
							types[item.nameType] = [];
						}
						types[item.nameType].push(item.nameCode);
					});
					return types;
				}
			}
		],
		typeAllowed: function (type, nameCode) {
			var typeData = this.get('docTypesCode')[type];
			return !nameCode
				? !!typeData
				: Array.isArray(typeData) &&
						!typeData.every(function (code) {
							return code != nameCode;
						});
		},
		flowTypesForHide: function (nameType, nameCode) {
			var flow = [];
			var typeData = this.get('docTypes');
			if (Array.isArray(typeData)) {
				typeData.forEach(function (item) {
					if (item.nameType === nameType && item.nameCode == nameCode) {
						flow.push(item.docFlowType);
					}
				});
			}
			return flow;
		}
	},
	OKV_CURRENCY: {
		fields: [
			{
				name: 'name',
				type: 'string'
			},
			{
				name: 'charCode',
				type: 'string'
			},
			{
				name: 'code',
				type: 'number'
			},
			{
				name: 'codeStr',
				mapping: 'code',
				type: 'string'
			},
			{
				name: 'countries',
				type: 'string'
			},
			{
				name: 'displayName',
				type: 'string',
				convert: function (v, record) {
					var code = record.get('codeStr');
					return code
						? record.get('charCode') + ' - ' + code + ' - ' + record.get('name')
						: record.get('name');
				}
			}
		],
		idProperty: 'code'
	},
	SIGN_INFO: {
		fields: [
			{
				name: 'beginDate',
				type: 'number'
			},
			{
				name: 'endDate',
				type: 'number'
			},
			{
				name: 'signDate',
				type: 'number'
			},
			{
				name: 'serialNumber',
				type: 'string'
			},
			{
				name: 'role',
				type: 'string'
			},
			{
				name: 'subjectName',
				type: 'string'
			},
			{
				name: 'issuer'
				//type: 'object'
			},
			{
				name: 'issuerName',
				type: 'string'
			},
			{
				name: 'sertData'
				//type: 'object'
			},
			{
				name: 'doverRegNumber',
				type: 'string'
			},
			{
				name: 'naimOrgDover',
				type: 'string'
			},
			{
				name: 'poaStatus',
				type: 'string'
			},
			{
				name: 'poaId',
				type: 'string'
			}
		]
	},
	DOCUMENTS_POA_ERRORS: {
		fields: [
			{
				name: 'doverRegNumber',
				type: 'string'
			},
			{
				name: 'docType',
				type: 'string'
			},
			{
				name: 'naimOrgDover',
				type: 'string'
			},
			{
				name: 'poaError',
				type: 'string'
			}
		]
	},
	TEMPLATE_WITH_STATUS: {
		fields: [
			{
				name: 'templateId',
				type: 'number'
			},
			{
				name: 'requestId',
				type: 'number'
			},
			{
				name: 'reportStatus',
				type: 'string'
			},
			{
				name: 'templateName',
				type: 'string'
			},
			{
				name: 'format',
				type: 'string'
			}
		],
		idProperty: 'templateId'
	},
	DOCUMENTS_GROUP_REPORTS: {
		fields: [
			{
				name: 'id',
				type: 'number'
			},
			{
				name: 'modifyDate',
				type: 'number'
			},
			{
				name: 'status',
				type: 'string'
			},
			{
				name: 'org'
				//type: 'object'
			},
			{
				name: 'requestList'
				//type: 'array'
			}
		]
	},
	DOCUMENTS_IN_PRINT_REPORT: {
		fields: [
			{
				name: 'docNumber',
				type: 'string'
			},
			{
				name: 'docDate',
				type: 'number'
			},
			{
				name: 'docType',
				type: 'string'
			},
			{
				name: 'state',
				type: 'string'
			}
		]
	},
	DOCUMENT_BASE: {
		fields: [
			{
				name: 'id',
				type: 'number'
			},
			{
				name: 'legacyId',
				type: 'number'
			},
			{
				name: 'creationDate',
				type: 'string'
			},
			{
				name: 'modifyDate',
				type: 'string'
			},
			{
				name: 'fromOrg'
				//type: 'object'
			},
			{
				name: 'toOrg'
				//type: 'object'
			},
			{
				name: 'doctime',
				type: 'string'
			},
			{
				name: 'type',
				type: 'string'
			},
			{
				name: 'state',
				type: 'string'
			},
			{
				name: 'businessState',
				type: 'string'
			},
			{
				name: 'legacyId',
				type: 'string'
			},
			{
				name: 'number',
				type: 'string'
			},
			{
				name: 'hasLinkedDocuments',
				type: 'boolean'
			},
			{
				name: 'countSignatures',
				type: 'number'
			},
			{
				name: 'agreement',
				type: 'string'
			},
			{
				name: 'attributes'
				//type: 'object'
			},
			{
				name: 'versionId',
				type: 'string'
			},
			{
				name: 'guid',
				type: 'string'
			},
			{
				name: 'totalSumm',
				type: 'string'
			},
			{
				name: 'archived',
				type: 'boolean'
			},
			{
				name: 'packageId',
				type: 'number'
			}
		]
	},
	DOCUMENT_BASE_ARCH: {
		extend: 'edi.models.DOCUMENT_BASE',
		fields: [
			{
				name: 'orderNumber',
				type: 'string',
				convert: function (value, record) {
					return edi.utils.getAttributeByName(record.get('attributes'), 'orderNumber');
				}
			},
			{
				name: 'desadvNumber',
				type: 'string',
				convert: function (value, record) {
					return edi.utils.getAttributeByName(record.get('attributes'), 'desadvNumber');
				}
			},
			{
				name: 'contractNumber',
				type: 'string',
				convert: function (value, record) {
					return edi.utils.getAttributeByName(record.get('attributes'), 'contractNumber');
				}
			},
			{
				name: 'deliveryGLN',
				type: 'string',
				convert: function (value, record) {
					return edi.utils.getAttributeByName(record.get('attributes'), 'deliveryGLN');
				}
			}
		]
	},
	DOCUMENT: {
		extend: 'edi.models.DOCUMENT_BASE',
		fields: [
			{
				name: 'needSignatures',
				type: 'number',
				convert: function (value, record) {
					return edi.document.actions.getSignCount(record.getData());
				}
			},
			{
				name: 'hasPart2',
				type: 'boolean',
				convert: function (value, record) {
					return !!edi.utils.getAttributeByName(record.get('attributes'), 'hasPart2');
				}
			},
			{
				name: 'rejectReason',
				type: 'string',
				convert: function (value, record) {
					return edi.utils.getAttributeByName(record.get('attributes'), 'REJECT_REASON');
				}
			},
			{
				name: 'documentVersion',
				type: 'string',
				convert: function (value, record) {
					return edi.utils.getAttributeByName(record.get('attributes'), 'documentVersion');
				}
			},
			{
				name: 'documentWarnings',
				type: 'string',
				convert: function (value, record) {
					return edi.utils.getAttributeByName(
						record.get('attributes'),
						'errorNomSredIdentTovAndSvedProslezh'
					);
				}
			},
			{
				name: 'documentFunctionCode',
				type: 'string',
				convert: function (value, record) {
					return edi.utils.getAttributeByName(record.get('attributes'), 'documentFunctionCode');
				}
			},
			{
				name: 'orderNumber',
				type: 'string',
				convert: function (value, record) {
					return edi.utils.getAttributeByName(record.get('attributes'), 'orderNumber');
				}
			},
			{
				name: 'desadvNumber',
				type: 'string',
				convert: function (value, record) {
					return edi.utils.getAttributeByName(record.get('attributes'), 'desadvNumber');
				}
			},
			{
				name: 'contractNumber',
				type: 'string',
				convert: function (value, record) {
					return edi.utils.getAttributeByName(record.get('attributes'), 'contractNumber');
				}
			},
			{
				name: 'doc_flow_id',
				type: 'string',
				convert: function (value, record) {
					return edi.utils.getAttributeByName(record.get('attributes'), 'doc_flow_id');
				}
			},
			{
				name: 'correctionNumber',
				type: 'string',
				convert: function (value, record) {
					return edi.utils.getAttributeByName(record.get('attributes'), 'correctionNumber');
				}
			},
			{
				name: 'orderCorrectionNumber',
				type: 'string',
				convert: function (value, record) {
					return edi.utils.getAttributeByName(record.get('attributes'), 'orderCorrectionNumber');
				}
			},
			{
				name: 'orderCorrectionDate',
				type: 'string',
				convert: function (value, record) {
					return edi.utils.getAttributeByName(record.get('attributes'), 'orderCorrectionDate');
				}
			},
			{
				name: 'totalNetAmount',
				type: 'string',
				convert: function (value, record) {
					return edi.utils.getAttributeByName(record.get('attributes'), 'totalNetAmount');
				}
			},
			{
				name: 'totalTaxAmount',
				type: 'string',
				convert: function (value, record) {
					return edi.utils.getAttributeByName(record.get('attributes'), 'totalTaxAmount');
				}
			},
			{
				name: 'viewedByReceiver',
				type: 'boolean'
			}
		]
	},
	DOCUMENTS_MODAL: {
		fields: [
			{
				name: 'id',
				type: 'number'
			},
			{
				name: 'type',
				type: 'string'
			},
			{
				name: 'state',
				type: 'string'
			},
			{
				name: 'error',
				type: 'string'
			}
		]
	},
	DOCUMENT_LINKED: {
		extend: 'edi.models.DOCUMENT',
		//idProperty: "recordId",
		fields: [
			//Для record root передается id (root: {id: 0}) при создании TreeStore
			//{
			//	name: "recordId",
			//	convert: function(v, record) {
			//		console.log(record)
			//		console.log(record?.data?.id)
			//		return record?.data?.id || 0;
			//	},
			//	type: "number"
			//},
			{
				name: 'expanded',
				type: 'boolean',
				convert: function (value, record) {
					if ('string' == typeof value && record.self.currentId != record.get('id')) {
						record.self.openMap = record.self.openMap ? record.self.openMap : {};
						value = record.self.openMap[record.get('id')] || false;
					}
					return value;
				}
			},
			{
				name: 'isCurrent',
				type: 'boolean',
				convert: function (value, record) {
					return record.self.currentId == record.get('id');
				}
			},
			{
				name: 'fromOrgName',
				type: 'string',
				convert: function (value, record) {
					return record.get('fromOrg')?.name;
				}
			},
			{
				name: 'toOrgName',
				type: 'string',
				convert: function (value, record) {
					return record.get('toOrg')?.name;
				}
			},
			{
				name: 'versionId',
				type: 'string'
			}
		]
	},
	DOCUMENT_LINKED_RECEIPTS: {
		extend: 'edi.models.DOCUMENT',
		fields: [
			{
				name: 'number',
				type: 'string'
			},
			{
				name: 'fromOrg'
				//type: "object"
			},
			{
				name: 'toOrg'
				//type: "object"
			},
			{
				name: 'creationDate',
				type: 'number'
			},
			{
				name: 'modifyDate',
				type: 'number'
			},
			{
				name: 'state',
				type: 'string'
			},
			{
				name: 'type',
				type: 'string'
			}
		]
	},
	DOCUMENTS_ALL_WARNING_GRID: {
		fields: [
			{
				name: 'type',
				type: 'string'
			},
			{
				name: 'totalSumm',
				type: 'string'
			}
		]
	},
	DEFERRED_REPORT: {
		fields: [
			{
				name: 'state',
				type: 'string'
			},
			{
				name: 'requestType',
				type: 'string'
			},
			{
				name: 'doc'
				//type: 'object'
			},
			{
				name: 'reportRequest'
				//type: 'object'
			},
			{
				name: 'org'
				//type: 'object'
			},
			{
				name: 'creationDate',
				type: 'string'
			},
			{
				name: 'attributes'
				//type: 'object'
			}
		]
	},
	CATALOG_V2: {
		fields: [
			{
				name: 'totalItemsCount',
				type: 'number'
			},
			{
				name: 'type',
				type: 'string'
			},
			{
				name: 'fromOrg'
				//type: 'object'
			},
			{
				name: 'toOrg'
				//type: 'object'
			},
			{
				name: 'fromNetwork'
				//type: 'object'
			},
			{
				name: 'toNetwork'
				//type: 'object'
			},
			{
				name: 'fromItem',
				//type: 'object',
				convert: function (value, record) {
					return record.get('fromOrg') || record.get('fromNetwork');
				}
			},
			{
				name: 'toItem',
				//type: 'object',
				convert: function (value, record) {
					return record.get('toOrg') || record.get('toNetwork');
				}
			},
			{
				name: 'creationDate',
				type: 'number'
			},
			{
				name: 'modifyDate',
				type: 'number'
			},
			{
				name: 'owner',
				type: 'number'
			},
			{
				name: 'state',
				type: 'string'
			},
			{
				name: 'attributes'
				//type: 'object'
			}
		]
	},
	PRODCAT_LINE: {
		fields: [
			{
				name: 'activityStatus',
				mapping: 'lineItem.activityStatus',
				type: 'string'
			},
			{
				name: 'lineNumber',
				mapping: 'lineItem.lineNumber',
				type: 'number'
			},
			{
				name: 'lineType',
				mapping: 'lineItem.lineType',
				type: 'string'
			},
			{
				name: 'ean',
				mapping: 'lineItem.ean',
				type: 'string'
			},
			{
				name: 'systemID',
				mapping: 'lineItem.systemID',
				type: 'number'
			},
			{
				name: 'buyerItemCode',
				mapping: 'lineItem.buyerItemCode',
				type: 'string'
			},
			{
				name: 'supplierItemCode',
				mapping: 'lineItem.supplierItemCode',
				type: 'string'
			},
			{
				name: 'previousEAN',
				mapping: 'lineItem.previousEAN',
				type: 'string'
			},
			{
				name: 'previousBuyerItemCode',
				mapping: 'lineItem.previousBuyerItemCode',
				type: 'string'
			},
			{
				name: 'previousSupplierItemCode',
				mapping: 'lineItem.previousSupplierItemCode',
				type: 'string'
			},
			{
				name: 'itemDescription',
				mapping: 'lineItem.itemDescription',
				type: 'string'
			},
			{
				name: 'itemDescriptionENG',
				mapping: 'lineItem.itemDescriptionENG',
				type: 'string'
			},
			{
				name: 'brandName',
				mapping: 'lineItem.brandName',
				type: 'string'
			},
			{
				name: 'brandCode',
				mapping: 'lineItem.brandCode',
				type: 'string'
			},
			{
				name: 'salesRegion',
				mapping: 'lineItem.salesRegion',
				type: 'string'
			},
			{
				name: 'campaignYear',
				type: 'string',
				mapping: 'lineItem.campaignYear'
			},
			{
				name: 'campaign',
				type: 'string',
				mapping: 'lineItem.campaign'
			},
			{
				name: 'colourCode',
				mapping: 'lineItem.colourCode',
				type: 'string'
			},
			{
				name: 'countryOfOrigin',
				mapping: 'lineItem.countryOfOrigin',
				type: 'string'
			},
			{
				name: 'colourName',
				type: 'string',
				mapping: 'lineItem.colourName'
			},
			{
				name: 'size',
				type: 'string',
				mapping: 'lineItem.size'
			},
			{
				name: 'subFamily',
				type: 'string',
				mapping: 'lineItem.subFamily'
			},
			{
				name: 'supplierPackCode',
				mapping: 'lineItem.packDetails.supplierPackCode',
				type: 'string'
			},
			{
				name: 'typePacking',
				mapping: 'lineItem.packDetails.typePacking',
				type: 'string'
			},
			{
				name: 'department',
				type: 'string',
				mapping: 'lineItem.department'
			},
			{
				name: 'family',
				type: 'string',
				mapping: 'lineItem.family'
			},
			{
				name: 'productType',
				mapping: 'lineItem.productType',
				type: 'string'
			},
			{
				name: 'itemDescriptionLong',
				mapping: 'lineItem.itemDescriptionLong',
				type: 'string'
			},
			{
				name: 'itemType',
				mapping: 'lineItem.itemType',
				type: 'string'
			},
			{
				name: 'itemGroupIdentificationCode',
				mapping: 'lineItem.itemGroupIdentificationCode',
				type: 'string'
			},
			{
				name: 'itemGroupDescription',
				mapping: 'lineItem.itemGroupDescription',
				type: 'string'
			},
			{
				name: 'assortmentGroup',
				mapping: 'lineItem.assortmentGroup',
				type: 'string'
			},
			{
				name: 'sellerLocationCode',
				mapping: 'lineItem.sellerLocationCode',
				type: 'string'
			},
			{
				name: 'promotionalQuantity',
				mapping: 'lineItem.promotionalQuantity',
				type: 'number'
			},
			{
				name: 'productLifespan',
				mapping: 'lineItem.productLifespan'
				//type: 'object'
			},
			{
				name: 'deliveryTerms',
				mapping: 'lineItem.deliveryTerms',
				type: 'number'
			},
			{
				name: 'unitOfMeasure',
				mapping: 'lineItem.unitOfMeasure',
				type: 'string'
			},
			{
				name: 'unitNetPrice',
				mapping: 'lineItem.unitNetPrice',
				type: 'number'
			},
			{
				name: 'promotionalPrice',
				type: 'number',
				mapping: 'lineItem.promotionalPrice'
			},
			{
				name: 'suggestedPrice',
				type: 'number',
				mapping: 'lineItem.suggestedPrice'
			},
			{
				name: 'discount',
				type: 'number',
				mapping: 'lineItem.discount'
			},
			{
				name: 'promotionCode',
				type: 'string',
				mapping: 'lineItem.promotionCode'
			},
			{
				name: 'customsDeclarationNumber',
				mapping: 'lineItem.customsDeclarationNumber',
				type: 'string'
			},
			{
				name: 'taxRate',
				mapping: 'lineItem.taxRate',
				type: 'number'
			},
			{
				name: 'taxCategoryCode',
				mapping: 'lineItem.taxCategoryCode',
				type: 'string'
			},
			{
				name: 'taxReference',
				mapping: 'lineItem.taxReference'
				//type: 'object'
			},
			{
				name: 'packDetails',
				mapping: 'lineItem.packDetails'
				//type: 'object'
			},
			{
				name: 'unitNetWeight',
				mapping: 'lineItem.unitNetWeight',
				type: 'number'
			},
			{
				name: 'remarks',
				mapping: 'lineItem.remarks',
				type: 'string'
			},
			{
				name: 'creationDate',
				type: 'number'
			},
			{
				name: 'modifyDate',
				type: 'number'
			},
			{
				name: 'id',
				type: 'number'
			}
		]
	},
	LOCCAT_LINE: {
		fields: [
			{
				name: 'accountNumber',
				type: 'string'
			},
			{
				name: 'activityStatus',
				mapping: 'lineItem.activityStatus',
				type: 'string'
			},
			{
				name: 'allowBackorder',
				type: 'number'
			},
			{
				name: 'allowViewStock',
				type: 'number'
			},
			{
				name: 'allowedUnitOfMeasure',
				type: 'string'
			},
			{
				name: 'amountDue',
				type: 'string'
			},
			{
				name: 'assortmentGroup',
				type: 'string'
			},
			{
				name: 'assortmentType',
				type: 'string'
			},
			{
				name: 'cityName',
				type: 'string'
			},
			{
				name: 'codeByReceiver',
				type: 'string'
			},
			{
				name: 'codeBySender',
				type: 'string'
			},
			{
				name: 'contactPerson',
				type: 'string'
			},
			{
				name: 'country',
				type: 'string'
			},
			{
				name: 'creationDate',
				type: 'number'
			},
			{
				name: 'creditAmount',
				type: 'string'
			},
			{
				name: 'discount',
				type: 'string'
			},
			{
				name: 'discountGroup',
				type: 'string'
			},
			{
				name: 'district',
				type: 'string'
			},
			{
				name: 'email',
				type: 'string'
			},
			{
				name: 'groupCodeByReceiver',
				type: 'string'
			},
			{
				name: 'groupCodeBySender',
				type: 'string'
			},
			{
				name: 'headquarters'
				//type: 'object'
			},
			{
				name: 'houseNumber',
				type: 'string'
			},
			{
				name: 'housing',
				type: 'string'
			},
			{
				name: 'id',
				type: 'number'
			},
			{
				name: 'iln',
				type: 'string'
			},
			{
				name: 'lineDescriptions',
				type: 'string'
			},
			{
				name: 'lineReference',
				type: 'string'
			},
			{
				name: 'lineType',
				type: 'string'
			},
			{
				name: 'locality',
				type: 'string'
			},
			{
				name: 'modifyDate',
				type: 'number'
			},
			{
				name: 'name',
				type: 'string'
			},
			{
				name: 'phoneNumber',
				type: 'string'
			},
			{
				name: 'postalCode',
				type: 'string'
			},
			{
				name: 'previousILN',
				type: 'string'
			},
			{
				name: 'roomNumber',
				type: 'string'
			},
			{
				name: 'salesRepresentative',
				type: 'string'
			},
			{
				name: 'sellerLocationCode',
				type: 'string'
			},
			{
				name: 'shipFrom'
				//type: 'object'
			},
			{
				name: 'state',
				type: 'string'
			},
			{
				name: 'stateCode',
				type: 'string'
			},
			{
				name: 'streetAndNumber',
				type: 'string'
			},
			{
				name: 'supplierUnitOfMeasure',
				type: 'string'
			},
			{
				name: 'systemID',
				type: 'string'
			},
			{
				name: 'taxID',
				type: 'string'
			},
			{
				name: 'taxRegistrationReasonCode',
				type: 'string'
			},
			{
				name: 'territoryCodeBySender',
				type: 'string'
			},
			{
				name: 'territoryName',
				type: 'string'
			},
			{
				name: 'type',
				type: 'string'
			},
			{
				name: 'utilizationRegisterNumber',
				type: 'string'
			}
		]
	},
	DELCAT_LINE: {
		extend: 'edi.models.LOCCAT_LINE',
		fields: [
			{
				name: 'latitude',
				type: 'string'
			},
			{
				name: 'longitude',
				type: 'string'
			}
		],
		idProperty: 'ILN'
	},
	DOCUMENT_LEGACY_APERAK: {
		extend: 'edi.models.DOCUMENT',
		fields: [
			{
				name: 'referenceDocType',
				type: 'string',
				convert: function (v, record) {
					return edi.utils.getAttributeByName(record.get('attributes'), 'referenceDocType') || '';
				}
			},
			{
				name: 'referenceDocNum',
				type: 'number',
				convert: function (v, record) {
					return edi.utils.getAttributeByName(record.get('attributes'), 'referenceDocNum');
				}
			},
			{
				name: 'referenceDocCode',
				type: 'string',
				convert: function (v, record) {
					return edi.utils.getAttributeByName(record.get('attributes'), 'referenceDocCode') || '';
				}
			}
		]
	},
	DOCUMENT_ON_SERVICE: {
		extend: 'edi.models.DOCUMENT',
		fields: [
			{
				name: 'senderSignatureExpected',
				type: 'boolean'
			},
			{
				name: 'file'
				//type: "object"
			}
		]
	},
	EDI_SLSRPT_REFERENCE_ELEMENTS: {
		fields: [
			{
				name: 'Reference-Id',
				type: 'string'
			},
			{
				name: 'Reference-Type',
				type: 'string'
			}
		]
	},
	EANCOM_LINE: {
		fields: [
			{
				name: 'LineNumber',
				type: 'number'
			},
			{
				name: 'EAN',
				type: 'string'
			},
			{
				name: 'ItemCode',
				type: 'string'
			},
			{
				name: 'Heading',
				type: 'string'
			},
			{
				name: 'CurrentPrice',
				type: 'string'
			},
			{
				name: 'NewPrice',
				type: 'string'
			}
		],
		idProperty: 'LineNumber'
	},
	EDI_DELFOR_LINE: {
		fields: [
			{
				name: 'LineNumber',
				type: 'number'
			},
			{
				name: 'BuyerCode',
				type: 'string'
			},
			{
				name: 'SupplierComment',
				type: 'string'
			},
			{
				name: 'OrderCalenderCode',
				type: 'string'
			},
			{
				name: 'DeliveryCalenderCode',
				type: 'string'
			},
			{
				name: 'ExeptionCalenderCode',
				type: 'string'
			},
			{
				name: 'DeliveryTerms',
				type: 'string'
			},
			{
				name: 'DeliveryPointIln',
				type: 'string'
			},
			{
				name: 'ChainName',
				type: 'string'
			},
			{
				name: 'ShipmentPoint'
				//type: 'array'
			}
		]
	},
	EDI_DELFOR_SHIPMENTPOINT: {
		fields: [
			{
				name: 'iln',
				type: 'string',
				convert: function (value, record) {
					return edi.utils.getObjectPropertyEx(record.data, 'nad.c082.e3039');
				}
			}
		]
	},
	PRODUCT_LINE: {
		fields: [
			{
				name: 'number',
				type: 'number'
			},
			{
				name: 'productData',
				type: 'string'
			},
			{
				name: 'name',
				type: 'string'
			},
			{
				name: 'characterProduct',
				type: 'string'
			},
			{
				name: 'grade',
				type: 'string'
			},
			{
				name: 'articleCode',
				type: 'string'
			},
			{
				name: 'code',
				type: 'string'
			},
			{
				name: 'unitName',
				type: 'string'
			},
			{
				name: 'unitCode',
				type: 'string'
			},
			{
				name: 'packageName',
				type: 'string'
			},
			{
				name: 'unitsInPackage',
				type: 'string'
			},
			{
				name: 'packagesAmount',
				type: 'string'
			},
			{
				name: 'grossWeight',
				type: 'string'
			},
			{
				name: 'nettoWeight',
				type: 'string'
			},
			{
				name: 'unitPrice',
				type: 'number'
			},
			{
				name: 'totalWithoutVAT',
				type: 'number'
			},
			{
				name: 'vat_display',
				type: 'string',
				convert: function (v, record) {
					var vat = record.get('vat');
					return '' != vat && null != vat ? parseFloat(vat) * 100 + '%' : '';
				}
			},
			{
				name: 'vat',
				type: 'string'
			},
			{
				name: 'totalVat',
				type: 'number'
			},
			{
				name: 'totalWithVAT',
				type: 'number'
			}
		],
		idProperty: 'number'
	},
	IFTMBF_LINE: {
		fields: [
			{
				name: 'iln',
				type: 'string'
			},
			{
				name: 'type',
				type: 'string'
			},
			{
				name: 'estimatedDispatchDate',
				type: 'string'
			},
			{
				name: 'estimatedDispatchTime',
				type: 'string'
			},
			{
				name: 'name',
				type: 'string'
			},
			{
				name: 'streetAndNumber',
				type: 'string'
			},
			{
				name: 'cityName',
				type: 'string'
			},
			{
				name: 'state',
				type: 'string'
			},
			{
				name: 'stateCode',
				type: 'string'
			},
			{
				name: 'country',
				type: 'string'
			},
			{
				name: 'contact',
				type: 'string'
			}
		],
		idProperty: 'lineNumber'
	},
	IFTMBF_ROUTE: {
		fields: [
			{
				name: 'ilnFrom',
				type: 'string'
			},
			{
				name: 'ilnTo',
				type: 'string'
			},
			{
				name: 'timing',
				type: 'string'
			},
			{
				name: 'addressFrom',
				type: 'string'
			},
			{
				name: 'addressTo',
				type: 'string'
			},
			{
				name: 'dateFrom',
				type: 'string'
			},
			{
				name: 'dateTo',
				type: 'string'
			}
		],
		idProperty: 'lineNumber'
	},
	IFTMBF_REF_DOCS: {
		fields: [
			{
				name: 'orderReferenceNumber',
				type: 'string'
			},
			{
				name: 'forwardingOrder',
				type: 'string'
			}
		]
	},
	IFTMBC_LINE: {
		fields: [
			{
				name: 'iln',
				type: 'string'
			},
			{
				name: 'estimatedDispatchDate',
				type: 'string'
			},
			{
				name: 'estimatedDispatchTime',
				type: 'string'
			},
			{
				name: 'type',
				type: 'string'
			},
			{
				name: 'name',
				type: 'string'
			},
			{
				name: 'streetAndNumber',
				type: 'string'
			},
			{
				name: 'cityName',
				type: 'string'
			},
			{
				name: 'state',
				type: 'string'
			},
			{
				name: 'stateCode',
				type: 'string'
			},
			{
				name: 'country',
				type: 'string'
			},
			{
				name: 'contact',
				type: 'string'
			}
		],
		idProperty: 'lineNumber'
	},
	IFTMBF_APPROVAL_ROUTE: {
		fields: [
			{
				name: 'routeFrom',
				type: 'string'
			},
			{
				name: 'routeTo',
				type: 'string'
			},
			{
				name: 'timing',
				type: 'string'
			},
			{
				name: 'addressfrom',
				type: 'string'
			},
			{
				name: 'addressto',
				type: 'string'
			},
			{
				name: 'dateFrom',
				type: 'string'
			},
			{
				name: 'dateTo',
				type: 'string'
			}
		],
		idProperty: 'lineNumber'
	},
	INVALID_RATE_PRODUCT: {
		fields: [
			{
				name: 'LineNumber',
				type: 'number'
			},
			{
				name: 'EAN',
				type: 'string'
			},
			{
				name: 'TaxRate',
				type: 'string'
			}
		],
		idProperty: 'lineNumber'
	},
	PRODUCT_TOTAL: {
		fields: [
			{
				name: 'totalRec',
				type: 'string'
			},
			{
				name: 'totalPackagesAmount',
				type: 'string'
			},
			{
				name: 'totalGrossWeight',
				type: 'string'
			},
			{
				name: 'totalNettoWeight',
				type: 'string'
			},
			{
				name: 'totalWithoutVAT',
				type: 'float'
			},
			{
				name: 'totalInvoice_VAT',
				type: 'string'
			},
			{
				name: 'totalWithVAT',
				type: 'float'
			}
		]
	},
	SUPPLY_AGREEMENT: {
		extend: 'edi.models.BASE_AGREEMENT',
		fields: [
			{
				name: 'partner'
				//type: 'object'
			},
			{
				name: 'owner'
				//type: 'object'
			},
			{
				name: 'agreementHeader',
				type: 'number'
			},
			{
				name: 'consumer',
				//type: 'object',
				convert: function (val, record) {
					var partner = record.get('partner');
					return partner ? partner : val;
				}
			},
			{
				name: 'supplier',
				//type: 'object',
				convert: function (val, record) {
					var owner = record.get('owner');
					return owner ? owner : val;
				}
			},
			{
				name: 'consumer.name',
				type: 'string',
				convert: function (val, record) {
					return record.get('consumer') ? record.get('consumer').name : '';
				}
			},
			{
				name: 'supplier.name',
				type: 'string',
				convert: function (val, record) {
					return record.get('supplier') ? record.get('supplier').name : '';
				}
			},
			{
				name: 'amountCertDocType',
				//type: "array",
				convert: function (value, record) {
					return (
						Ext.decode(
							edi.utils.getAttributeByName(record.get('attributes'), 'amountCertificationDocumentFormat'),
							true
						) || []
					);
				}
			},
			{
				name: 'receiptAckDocType',
				//type: "array",
				convert: function (value, record) {
					return (
						Ext.decode(
							edi.utils.getAttributeByName(
								record.get('attributes'),
								'receiptAcknowledgementDocumentFormat'
							),
							true
						) || []
					);
				}
			},
			{
				name: 'dueDate',
				type: 'number'
			},
			{
				name: 'additionalAgreementNumber',
				type: 'string'
			},
			{
				name: 'additionalAgreementDate',
				type: 'number'
			},
			{
				name: 'numberAndValidity',
				type: 'string',
				convert: function (value, record) {
					if (record.get('id')) {
						value = record.get('number');
						var perpetual = record.get('perpetual'),
							validFrom = record.get('validFrom'),
							validTo = record.get('validTo');
						value +=
							' (' +
							edi.renderers.dateFromMs(validFrom) +
							'-' +
							(perpetual ? '...' : edi.renderers.dateFromMs(validTo)) +
							')';
						value = edi.utils.safeString(value, true, true);
					} else {
						value = edi.i18n.getMessage('form.combo.not.selected');
					}
					return value;
				}
			}
		]
	},
	ROUTE_STEP: {
		fields: [
			{
				name: 'index',
				type: 'number'
			},
			{
				name: 'position'
				//type: 'object'
			},
			{
				name: 'actionType',
				type: 'string'
			}
		],
		idProperty: 'index'
	},
	INVITATION: {
		fields: [
			{
				name: 'id',
				type: 'number'
			},
			{
				name: 'acceptor'
				//type: 'object'
			},
			{
				name: 'creationDate',
				type: 'number'
			},
			{
				name: 'rejectReason',
				type: 'string'
			},
			{
				name: 'status',
				type: 'string'
			},
			{
				name: 'initiator'
				//type: 'object'
			},
			{
				name: 'modifyDate',
				type: 'number'
			},
			{
				name: 'initiator.name',
				type: 'string',
				convert: function (value, record) {
					return record.get('initiator').name;
				}
			},
			{
				name: 'acceptor.name',
				type: 'string',
				convert: function (value, record) {
					return record.get('acceptor').name;
				}
			}
		]
	},
	EXPENDITURE_TYPE: {
		fields: [
			{
				name: 'id',
				type: 'number'
			},
			{
				name: 'name',
				type: 'string'
			},
			{
				name: 'org'
				//type: 'object'
			},
			{
				name: 'creationDate',
				type: 'number'
			},
			{
				name: 'modifyDate',
				type: 'number'
			}
		]
	},
	PAYMENT_DOCUMENTS_INFORMATION: {
		fields: [
			{
				name: 'NomerPRD',
				type: 'string'
			},
			{
				name: 'DataPRD',
				type: 'string'
			}
		]
	},
	TEXT_INFORMATION: {
		fields: [
			{
				name: 'Identif',
				type: 'string'
			},
			{
				name: 'Znachen',
				type: 'string'
			}
		]
	},
	FIELD_LINE_INFORMATION: {
		fields: [
			{
				name: 'Identif',
				type: 'string'
			},
			{
				name: 'Znachen',
				type: 'string'
			}
		]
	},
	DOCUMENT_LOCK: {
		fields: [
			{
				name: 'id',
				type: 'number'
			},
			{
				name: 'typeLock',
				type: 'string'
			},
			{
				name: 'businessModule',
				type: 'string'
			},
			{
				name: 'active',
				type: 'string'
			},
			{
				name: 'code',
				type: 'string'
			},
			{
				name: 'additionalInfo',
				type: 'string'
			},
			{
				name: 'creationDate',
				type: 'string'
			},
			{
				name: 'modifyDate',
				type: 'string'
			}
		]
	},
	DOCUMENT_ATTRIBUTES: {
		fields: [
			{
				name: 'name',
				type: 'string'
			},
			{
				name: 'value',
				type: 'string'
			}
		]
	},
	DOCUMENT_PASSPORT_TIME_MARK: {
		fields: [
			{
				name: 'name',
				type: 'string'
			},
			{
				name: 'value',
				type: 'string'
			},
			{
				name: 'mark'
				//type: 'object'
			}
		]
	},
	DOCUMENT_PASSPORT_MARK: {
		fields: [
			{
				name: 'SystemID',
				type: 'string'
			},
			{
				name: 'SystemDocumentID',
				type: 'string'
			},
			{
				name: 'DocumentSource',
				type: 'string'
			},
			{
				name: 'SourceProtocol',
				type: 'string'
			},
			{
				name: 'ReadByReceiver',
				type: 'string'
			},
			{
				name: 'ReadByReceiverProtocol',
				type: 'string'
			},
			{
				name: 'InboxIntergrationChannelID',
				type: 'string'
			},
			{
				name: 'OutboxIntergrationChannelID',
				type: 'string'
			},
			{
				name: 'Timestamps',
				mapping: 'Timestamps.Timestamps'
				//type: 'array'
			}
		]
	},
	DELIVERY_COORDINATION: {
		fields: [
			{
				name: 'TruckQuantity',
				type: 'string'
			},
			{
				name: 'DeliveryDateTime',
				type: 'string'
			},
			{
				name: 'EndOfLoadingTime',
				type: 'string'
			}
		]
	},
	GENERATION_NUMBER_DOC: {
		fields: [
			{
				name: 'creationDate',
				type: 'number'
			},
			{
				name: 'id',
				type: 'number'
			},
			{
				name: 'numberPart',
				type: 'number'
			},
			{
				name: 'maxNumber',
				type: 'number'
			},
			{
				name: 'policy',
				type: 'string'
			},
			{
				name: 'stringFormat',
				type: 'string'
			},
			{
				name: 'modifyDate',
				type: 'number'
			},
			{
				name: 'organization'
				//type: 'object'
			},
			{
				name: 'typeDoc',
				type: 'string'
			},
			{
				name: 'currentNumber',
				type: 'number'
			},
			{
				name: 'needToFixCurrentNumber',
				type: 'boolean'
			}
		]
	},
	SLSRPT_LINE: {
		fields: [
			{
				name: 'number',
				type: 'number'
			},
			{
				name: 'id',
				type: 'number'
			},
			{
				name: 'header',
				type: 'number'
			},
			{
				name: 'sku',
				type: 'string'
			},
			{
				name: 'plu',
				type: 'string'
			},
			{
				name: 'itemDescription',
				type: 'string'
			},
			{
				name: 'saleDate',
				type: 'date'
			},
			{
				name: 'despatchQuantity',
				type: 'number'
			},
			{
				name: 'unitOfMeasure',
				type: 'string'
			},
			{
				name: 'unitPackSize',
				type: 'number'
			},
			{
				name: 'unitNetPrice',
				type: 'number'
			},
			{
				name: 'unitGrossPrice',
				type: 'number'
			},
			{
				name: 'taxRate',
				type: 'string'
			},
			{
				name: 'totalNetAmount',
				type: 'number'
			},
			{
				name: 'totalGrossAmount',
				type: 'number'
			},
			{
				name: 'totalTaxAmount',
				type: 'number'
			},
			{
				name: 'locationPartyTaxID',
				type: 'string'
			},
			{
				name: 'utilizationRegisterNumber',
				type: 'string'
			},
			{
				name: 'locationPartyName',
				type: 'string'
			},
			{
				name: 'codeBySender',
				type: 'string'
			},
			{
				name: 'codeByReceiver',
				type: 'string'
			},
			{
				name: 'state',
				type: 'string'
			}
		]
	},
	ORGANIZATION_CATALOG: {
		fields: [
			{
				name: 'iln',
				type: 'string'
			},
			{
				name: 'systemID',
				type: 'string'
			},
			{
				name: 'previousILN',
				type: 'string'
			},
			{
				name: 'taxID',
				type: 'string',
				convert: function (val, record) {
					if (!val || !val.length) {
						var hqData = record.get('headquarters');
						if (hqData && hqData.taxID) {
							val = hqData.taxID;
						}
					}
					return val;
				}
			},
			{
				name: 'taxRegistrationReasonCode',
				type: 'string'
			},
			{
				name: 'accountNumber',
				type: 'string'
			},
			{
				name: 'codeBySender',
				type: 'string'
			},
			{
				name: 'codeByReceiver',
				type: 'string'
			},
			{
				name: 'utilizationRegisterNumber',
				type: 'string'
			},
			{
				name: 'name',
				type: 'string'
			},
			{
				name: 'roomNumber',
				type: 'string'
			},
			{
				name: 'housing',
				type: 'string'
			},
			{
				name: 'houseNumber',
				type: 'string'
			},
			{
				name: 'streetAndNumber',
				type: 'string'
			},
			{
				name: 'cityName',
				type: 'string'
			},
			{
				name: 'locality',
				type: 'string'
			},
			{
				name: 'district',
				type: 'string'
			},
			{
				name: 'state',
				type: 'string'
			},
			{
				name: 'stateCode',
				type: 'string'
			},
			{
				name: 'postalCode',
				type: 'string'
			},
			{
				name: 'country',
				type: 'string'
			},
			{
				name: 'contactPerson',
				type: 'string'
			},
			{
				name: 'phoneNumber',
				type: 'string'
			},
			{
				name: 'email',
				type: 'string'
			},
			{
				name: 'web',
				type: 'string'
			},
			{
				name: 'type',
				type: 'string'
			},
			{
				name: 'lineType',
				type: 'string'
			},
			{
				name: 'sellerLocationCode',
				type: 'string'
			},
			{
				name: 'territoryCodeBySender',
				type: 'string'
			},
			{
				name: 'territoryName',
				type: 'string'
			},
			{
				name: 'supplierUnitOfMeasure',
				type: 'string'
			},
			{
				name: 'discountGroup',
				type: 'string'
			},
			{
				name: 'discount',
				type: 'string'
			},
			{
				name: 'assortmentGroup',
				type: 'string'
			},
			{
				name: 'assortmentType',
				type: 'string'
			},
			{
				name: 'allowBackorder',
				type: 'string'
			},
			{
				name: 'allowViewStock',
				type: 'string'
			},
			{
				name: 'allowedUnitOfMeasure',
				type: 'string'
			},
			{
				name: 'creditAmount',
				type: 'string'
			},
			{
				name: 'amountDue',
				type: 'string'
			},
			{
				name: 'groupCodeBySender',
				type: 'string'
			},
			{
				name: 'groupCodeByReceiver',
				type: 'string'
			},
			{
				name: 'headquarters'
				//type: 'object'
			},
			{
				name: 'shipFrom'
				//type: 'object'
			}
		],
		idProperty: 'iln'
	},
	DOCUMENTS_SHARING_CONTEXT: {
		fields: [
			{
				name: 'id',
				type: 'number'
			},
			{
				name: 'docType',
				type: 'string',
				convert: function (value, record) {
					return record.get('docId').type;
				}
			},
			{
				name: 'fromOrgName',
				type: 'string',
				convert: function (value, record) {
					return record.get('docId').fromOrgName;
				}
			},
			{
				name: 'toOrgName',
				type: 'string',
				convert: function (value, record) {
					return record.get('docId').toOrgName;
				}
			},
			{
				name: 'docNumber',
				type: 'string',
				convert: function (value, record) {
					return record.get('docId').number;
				}
			},
			{
				name: 'docId'
				//type: 'object'
			},
			{
				name: 'onLooker',
				type: 'string',
				convert: function (value) {
					return value ? value.name : '';
				}
			},
			{
				name: 'creationDate',
				type: 'number'
			},
			{
				name: 'modifyDate',
				type: 'number'
			}
		]
	},
	PARTNER_RETAIL_NETWORKS: {
		fields: [
			{
				name: 'id',
				type: 'number'
			},
			{
				name: 'name',
				type: 'string'
			},
			{
				name: 'gln',
				type: 'string'
			}
		]
	},
	CATALOG_FROM_ORG_COMBOBOX: {
		fields: [
			{
				name: 'id',
				type: 'string'
			},
			{
				name: 'name',
				type: 'string',
				convert: function (value, record) {
					const isOrg = record.get('isOrg');
					if (!isOrg) {
						return `${value} (${edi.i18n.getMessage('organization.type.retailnetwork')})`;
					}
					return value;
				}
			},
			{
				name: 'isOrg',
				type: 'boolean'
			}
		]
	},
	REPORT_TEMPLATES: {
		fields: [
			{
				name: 'id',
				type: 'string'
			},
			{
				name: 'displayName',
				type: 'string'
			},
			{
				name: 'docType',
				type: 'string'
			},
			{
				name: 'templates'
				//type: 'array'
			},
			{
				name: 'modifyDate',
				type: 'number'
			}
		]
	},
	X5_AGREEMENT_CONTAINER: {
		fields: [
			{
				name: 'id',
				type: 'string'
			},
			{
				name: 'type',
				type: 'string'
			},
			{
				name: 'fromOrg'
				//type: "object"
			},
			{
				name: 'toOrg'
				//type: "object"
			},
			{
				name: 'doctime',
				type: 'number'
			},
			{
				name: 'creationDate',
				type: 'number'
			},
			{
				name: 'modifyDate',
				type: 'number'
			}
		]
	},
	ORG_DOCUMENT_DATA_FIELD_LINE_VALUES: {
		fields: [
			{
				name: 'code',
				type: 'string'
			},
			{
				name: 'value',
				type: 'string'
			}
		]
	},
	EXPORT_GROUP_REQUEST: {
		fields: [
			{
				name: 'id',
				type: 'number'
			},
			{
				name: 'percentageOfReadiness ',
				type: 'number'
			},
			{
				name: 'exportType',
				type: 'string'
			},
			{
				name: 'state',
				type: 'string'
			},
			{
				name: 'creationDate',
				type: 'number'
			},
			{
				name: 'modifyDate',
				type: 'number'
			},
			{
				name: 'user'
				//type: 'object'
			}
		]
	},
	DOCUMENT_DSF_RECADV_DIFF_ACT: {
		fields: [
			{
				name: 'LineNumber',
				type: 'number',
				convert: function (value, record) {
					return edi.utils.getObjectProperty(record.data, 'lin.e1082');
				}
			},
			{
				name: 'plu',
				type: 'string',
				convert: function (value, record) {
					return edi.utils.getObjectProperty(record.data, 'pia.0.c212.0.e7140');
				}
			},
			{
				name: 'name',
				type: 'string',
				convert: function (value, record) {
					return edi.utils.getObjectProperty(record.data, 'imd.0.c273.e7008');
				}
			},
			{
				name: 'acceptedAmount',
				type: 'string',
				convert: function (value, record) {
					return edi.utils.getObjectProperty(record.data, 'qty.0.c186.e6060');
				}
			},
			{
				name: 'unitOfMeasure',
				type: 'string',
				convert: function (value, record) {
					return edi.utils.getObjectProperty(record.data, 'qty.0.c186.e6411');
				}
			},
			{
				name: 'inadequateQuality',
				type: 'string',
				convert: function (value, record) {
					return edi.utils.getObjectPropertyEx(record.data, 'qvr[c279.e6063==96]c279.e6064');
				}
			},
			{
				name: 'expired',
				type: 'string',
				convert: function (value, record) {
					return edi.utils.getObjectPropertyEx(record.data, 'qvr[c279.e6063==65]c279.e6064');
				}
			},
			{
				name: 'damage',
				type: 'string',
				convert: function (value, record) {
					return edi.utils.getObjectPropertyEx(record.data, 'qvr[c279.e6063==124]c279.e6064');
				}
			},
			{
				name: 'overSpecified',
				type: 'string',
				convert: function (value, record) {
					return edi.utils.getObjectPropertyEx(record.data, 'qvr[c279.e6063==81]c279.e6064');
				}
			},
			{
				name: 'discrepanciesReason',
				type: 'string',
				convert: function (value, record) {
					var result = [];

					record.data.qvr?.forEach(function (item) {
						if (edi.utils.getObjectProperty(item, 'c279.e6064') > 0) {
							result.push(edi.utils.getObjectProperty(item, 'c960.e4294'));
						}
					});

					return result.join(', ');
				}
			},
			{
				name: 'deficit',
				type: 'string',
				convert: function (value, record) {
					return edi.utils.getObjectPropertyEx(record.data, 'qvr[c279.e6063==119]c279.e6064');
				}
			}
		]
	},
	DOCUMENT_DELFOR_CALENDAR: {
		idProperty: 'Delshchedulecode',
		fields: [
			{
				name: 'Linenumber',
				type: 'number'
			},
			{
				name: 'Delshchedulecode',
				type: 'string'
			},
			{
				name: 'Delshchedulename',
				type: 'string'
			},
			{
				name: 'Startdate',
				type: 'string'
			},
			{
				name: 'Enddate',
				type: 'string'
			}
		]
	},
	DOCUMENT_LOGSET: {
		fields: [
			{
				name: 'LineNumber',
				type: 'number'
			},
			{
				name: 'multiplicity',
				type: 'string'
			},
			{
				name: 'minimumWeight',
				type: 'string'
			},
			{
				name: 'deliveryTime',
				type: 'string'
			},
			{
				name: 'postTime',
				type: 'string'
			},
			{
				name: 'condition',
				type: 'string'
			},
			{
				name: 'conditionFormat',
				type: 'string',
				convert: function (value, record) {
					return edi.i18n.getMessage('document.logset.condition.' + record.get('condition'));
				}
			},
			{
				name: 'deliveryType',
				type: 'string'
			},
			{
				name: 'deliveryTypeFormat',
				type: 'string',
				convert: function (value, record) {
					return edi.i18n.getMessage('document.logset.delivery.type.' + record.get('deliveryType'));
				}
			},
			{
				name: 'schedule',
				type: 'string'
			},
			{
				name: 'minPallets',
				type: 'string'
			},
			{
				name: 'pallets',
				type: 'string'
			},
			{
				name: 'minPurchase',
				type: 'string'
			},
			{
				name: 'deliveryTimeFromSending',
				type: 'string'
			},
			{
				name: 'frequency',
				type: 'string'
			},
			{
				name: 'confirmation',
				type: 'string'
			},
			{
				name: 'deliveryPointIln',
				type: 'string'
			},
			{
				name: 'branch',
				type: 'string'
			},
			{
				name: 'conditionStorage',
				type: 'string'
			},
			{
				name: 'hierarchyLevel',
				type: 'string'
			},
			{
				name: 'shipmentPointIln',
				type: 'string'
			},
			{
				name: 'shipmentPointAddress',
				type: 'string'
			},
			{
				name: 'shipmentPointLatitude',
				type: 'string'
			},
			{
				name: 'shipmentPointLongitude',
				type: 'string'
			},
			{
				name: 'supplierComment',
				type: 'string'
			}
		]
	},
	DOCUMENTS_NOTIFY: {
		fields: [
			{
				name: 'id',
				type: 'number'
			},
			{
				name: 'children'
				//type: 'array'
			},
			{
				name: 'creationDate',
				type: 'number'
			},
			{
				name: 'modifyDate',
				type: 'number'
			},
			{
				name: 'name',
				type: 'string'
			},
			{
				name: 'value',
				type: 'string'
			}
		]
	},
	PARTIN_DELIVERY_PLACE: {
		fields: [
			{
				name: 'name',
				type: 'string'
			},
			{
				name: 'iln',
				type: 'string'
			}
		]
	},
	PARTIN_SELECTION_MODAL: {
		fields: [
			{
				name: 'name',
				type: 'string',
				useNull: true
			},
			{
				name: 'iln',
				type: 'int',
				useNull: true
			},
			{
				name: 'taxId',
				type: 'string'
			},
			{
				name: 'taxRegistrationReasonCode',
				type: 'string',
				useNull: true
			},
			{
				name: 'utilizationRegisterNumber',
				type: 'string',
				useNull: true
			},
			{
				name: 'enterpriseClassificationCode',
				type: 'string',
				useNull: true
			},
			{
				name: 'codeBySender',
				type: 'string',
				useNull: true
			},
			{
				name: 'codeByReceiver',
				type: 'string',
				useNull: true
			},
			{
				name: 'roomNumber',
				type: 'string',
				useNull: true
			},
			{
				name: 'housing',
				type: 'string',
				useNull: true
			},
			{
				name: 'houseNumber',
				type: 'string',
				useNull: true
			},
			{
				name: 'streetAndNumber',
				type: 'string',
				useNull: true
			},
			{
				name: 'cityName',
				type: 'string',
				useNull: true
			},
			{
				name: 'locality',
				type: 'string',
				useNull: true
			},
			{
				name: 'district',
				type: 'string',
				useNull: true
			},
			{
				name: 'state',
				type: 'string',
				useNull: true
			},
			{
				name: 'stateCode',
				type: 'string',
				useNull: true
			},
			{
				name: 'postalCode',
				type: 'string',
				useNull: true
			},
			{
				name: 'country',
				type: 'string',
				useNull: true
			},
			{
				name: 'additionalInformation',
				type: 'string',
				useNull: true
			},
			{
				name: 'tradeFormat',
				type: 'string',
				useNull: true
			},
			{
				name: 'format',
				type: 'string',
				useNull: true
			},
			{
				name: 'formatArea',
				type: 'string',
				useNull: true
			},
			{
				name: 'numberOfCashDesks',
				type: 'string',
				useNull: true
			},
			{
				name: 'status',
				type: 'string',
				useNull: true
			},
			{
				name: 'storeOpeningDate',
				type: 'string'
			},
			{
				name: 'dateNewOrders',
				type: 'string'
			},
			{
				name: 'contactPerson',
				type: 'string',
				useNull: true
			},
			{
				name: 'phoneNumber',
				type: 'string',
				useNull: true
			},
			{
				name: 'eMail',
				type: 'string',
				useNull: true
			},
			{
				name: 'latitude',
				type: 'string',
				useNull: true
			},
			{
				name: 'longitude',
				type: 'string',
				useNull: true
			},
			{
				name: 'headquarter'
				// type: 'object'
			},
			{
				name: 'branch'
				// type: 'object'
			},
			{
				name: 'franchisee'
				// type: 'object'
			}
		]
	},
	POA_SELECT_LIST: {
		fields: [
			{
				name: 'id',
				type: 'number'
			},
			{
				name: 'internalPoaNumber',
				type: 'string'
			},
			{
				name: 'poaNumber',
				type: 'string'
			},
			{
				name: 'doverRegNumber',
				type: 'string'
			},
			{
				name: 'creationDate',
				type: 'number'
			},
			{
				name: 'validFrom',
				type: 'number'
			},
			{
				name: 'validTo',
				type: 'number'
			},
			{
				name: 'orgName',
				type: 'string'
			},
			{
				name: 'principals',
				type: 'string'
			},
			{
				name: 'confidants'
			},
			{
				name: 'state',
				type: 'string'
			}
		]
	},
	WEB_UPLOAD_DOCTYPES: {
		fields: [
			{
				name: 'id',
				type: 'string'
			},
			{
				name: 'docType',
				type: 'string'
			},
			{
				name: 'name',
				type: 'string'
			},
			{
				name: 'version',
				type: 'string'
			}
		]
	}
});
