import { createCombo, createTextField } from '@Components/fields';
import { createProxyConfig } from '@Components/storeComponents';
import { createPanel } from '@Components/panels';
import { createDateRangeField, createAutocomplete } from '@UIkit/components/fields';
import { createProductCatalogItemModal } from '@Edi/specialComponents/productCatalogItemModal';
import { createLocationCatalogItemModal } from '@Edi/specialComponents/locationCatalogItemModal';

const createArgs = function (values) {
	if (values['dateFrom']) {
		values.dateFrom = edi.utils.getSearchTime(values['dateFrom'], edi.constants.DATE_FORMAT.SERVER);
	}
	if (values['dateTo']) {
		values.dateTo = edi.utils.getSearchTime(
			values['dateTo'],
			edi.constants.DATE_FORMAT.SERVER,
			edi.constants.SEARCH_DAY_IN_MS
		);
	}
	if (values['create-dateFrom']) {
		values.creationDateFrom = edi.utils.getUTCTimeZoneFree(
			values['create-dateFrom'],
			edi.constants.DATE_FORMAT.SERVER
		);
		delete values['create-dateFrom'];
	}
	if (values['create-dateTo']) {
		values.creationDateTo = edi.utils.getUTCTimeZoneFree(
			values['create-dateTo'],
			edi.constants.DATE_FORMAT.SERVER,
			edi.constants.SEARCH_DAY_IN_MS
		);
		delete values['create-dateTo'];
	}
	edi.utils.clearEmptyValues(values);
	return values;
};

const createFormItems = function (props) {
	const { toOrgField } = props || {};
	const catalogTypes = [];
	const catTypes = edi.constants.CATALOGS;
	for (let i = 0; i < catTypes.length; i++) {
		catalogTypes.push({
			id: catTypes[i],
			name: edi.i18n.getMessage('documents.doctype.' + catTypes[i])
		});
	}
	const formItemsMap = {
		type: createCombo({
			fieldLabel: edi.i18n.getMessage('column.catalog.type'),
			name: 'type',
			store: edi.stores.createMemoryStore(catalogTypes, 'SIMPLE', true)
		}),
		toOrgId: toOrgField
			? toOrgField
			: createAutocomplete(
					{
						name: 'to_org_id',
						fieldLabel: edi.i18n.getMessage('catalogs.grid.filter.toOrg'),
						showQtips: true
					},
					{
						proxy: createProxyConfig({
							type: 'ajax',
							url: edi.rest.services.USER.PARTNERS.GET
						}),
						autoLoad: true,
						model: edi.models.getModel('ORGANIZATIONS')
					}
			  ),
		[edi.constants.DEFAULT.FILTER.FIELDS.CREATION_DATE]: createDateRangeField({
			chipsModalTitle: edi.i18n.getMessage('document.date.create'),
			fieldsConfig: {
				common: {
					invalidText: edi.i18n.getMessage('invalid.date.format.fns'),
					emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy'),
					prefix: 'create'
				},
				from: {
					chipTitle: edi.i18n.getMessage('document.date.create') + ': ',
					fieldLabel: edi.i18n.getMessage('document.date.create.from')
				},
				to: {
					chipTitle: ' — ',
					fieldLabel: edi.i18n.getMessage('document.date.create.to')
				}
			}
		})
	};
	const items = [
		createPanel({
			layout: {
				type: 'grid',
				area: [[4, 4, 4]]
			},
			items: [
				formItemsMap.type,
				formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.CREATION_DATE],
				formItemsMap.toOrgId
			]
		})
	];
	return {
		formItemsMap,
		items
	};
};

const createProdcatFormItems = function () {
	const formItemsMap = {
		itemDescription: createTextField({
			fieldLabel: edi.i18n.getMessage('column.item.description'),
			name: 'itemDescription'
		}),
		buyerItemCode: createTextField({
			fieldLabel: edi.i18n.getMessage('column.buyer.item.code'),
			name: 'buyerItemCode'
		}),
		ean: createTextField({
			fieldLabel: edi.i18n.getMessage('column.ean'),
			name: 'ean'
		}),
		supplierItemCode: createTextField({
			fieldLabel: edi.i18n.getMessage('column.supplier.item.code'),
			name: 'supplierItemCode'
		})
	};
	const items = [
		createPanel({
			layout: {
				type: 'grid',
				area: [[12], [6, 6], [6, 6]]
			},
			items: [
				formItemsMap.label,
				formItemsMap.itemDescription,
				formItemsMap.ean,
				formItemsMap.buyerItemCode,
				formItemsMap.supplierItemCode
			]
		})
	];

	return {
		formItemsMap,
		items
	};
};

const createLoccatFormItems = function () {
	const formItemsMap = {
		name: createTextField({
			fieldLabel: edi.i18n.getMessage('column.name'),
			name: 'name'
		}),
		codeBySender: createTextField({
			fieldLabel: edi.i18n.getMessage('column.code.by.sender'),
			name: 'codeBySender'
		}),
		iln: createTextField({
			fieldLabel: edi.i18n.getMessage('company.gln'),
			name: 'iln'
		}),
		codeByReceiver: createTextField({
			fieldLabel: edi.i18n.getMessage('column.code.by.receiver'),
			name: 'codeByReceiver'
		})
	};
	const items = [
		createPanel({
			layout: {
				type: 'grid',
				area: [[12], [6, 6], [6, 6]]
			},
			items: [
				formItemsMap.label,
				formItemsMap.name,
				formItemsMap.iln,
				formItemsMap.codeBySender,
				formItemsMap.codeByReceiver
			]
		})
	];

	return {
		formItemsMap,
		items
	};
};

const handleRowClick = function (props) {
	const { data, partinModuleName, catalogModuleName } = props || {};
	const moduleTitle = edi.i18n.getMessage('catalog.type.' + edi.constants.CATALOG_TYPES[data?.type]);
	let catalogNumber = edi.utils.getAttributeByName(data?.attributes, 'catalogNumber') || '';
	catalogNumber = catalogNumber ? ' №' + catalogNumber : '';
	switch (data?.type) {
		case edi.constants.DOCUMENT_TYPES.LEGACY_PARTIN:
			edi.core.openModule(
				partinModuleName ?? 'partin.details',
				data,
				moduleTitle + catalogNumber,
				false,
				data.type + data.id
			);
			break;
		default:
			edi.core.openModule(catalogModuleName ?? 'catalog.details', data, moduleTitle, false, data.type + data.id);
	}
};

const showItemDetails = function (item, catalogType) {
	if (edi.permissions.hasPermission('READ_CATALOG_V2')) {
		let modalMethod;
		if (catalogType === edi.constants.DOCUMENT_TYPES.LEGACY_PRODCAT) {
			modalMethod = createProductCatalogItemModal;
		} else {
			modalMethod = createLocationCatalogItemModal;
		}
		modalMethod(
			item,
			null,
			{
				readOnly: true
			},
			catalogType === edi.constants.DOCUMENT_TYPES.LEGACY_DELCAT
		);
	}
};

export { createArgs, createFormItems, handleRowClick, showItemDetails, createProdcatFormItems, createLoccatFormItems };
