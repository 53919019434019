// @ts-ignore
import { createComboTree } from '@UIkit/components/fields/Combobox/ComboboxTree';

export interface DocTypeTreeStoreItem {
	id: string | number;
	name: string;
	items?: (DocTypeTreeStoreItem | string)[];
}
export interface CreateDocTypeTreeItem extends Partial<DocTypeTreeStoreItem> {
	order?: number;
}

const createComboDocType = function (config: AnyObject) {
	config = 'object' == typeof config ? config : {};

	const createDocTypeTree = ({ items }: { items: (CreateDocTypeTreeItem | string)[] }): DocTypeTreeStoreItem[] => {
		const copyItems = Ext.clone(items) ?? [];
		copyItems.sort((o1, o2) => {
			if (typeof o1 === 'object' && o1.order && typeof o2 === 'object' && o2.order) return o1.order - o2.order;
			return 0;
		});
		return copyItems.map((item) => {
			if (typeof item === 'object') {
				const { id, name, items } = item;
				const isCategory = !!items?.length;

				const title =
					name ??
					edi.i18n.getMessage(`${isCategory ? 'document.type.category.' : 'documents.doctype.'}com.edisoft:general_ui:war:1.0.19.2-RC7`);
				return {
					id: id ?? Ext.id(),
					name: title,
					items: isCategory && Array.isArray(items) ? createDocTypeTree({ items }) : undefined
				};
			} else {
				return {
					id: item,
					name: edi.i18n.getMessage('documents.doctype.' + item)
				};
			}
		});
	};

	const initDocTypeStore = function () {
		const type_categories = (config.itemsTree ?? Ext.clone(edi.stores.data.docTypesTree)) as {
			[categoryName: string]: DocTypeTreeStoreItem;
		};
		return edi.stores.createInlineStore(
			createDocTypeTree({
				items: Object.entries(type_categories).map(([categoryName, items]) => ({
					...items,
					id: categoryName
				}))
			}),
			'DOCTYPES_TREE',
			undefined,
			{
				addEmptyRecord: true
			}
		);
	};

	const defaults = {
		name: 'includeDocTypes',
		store: initDocTypeStore(),
		multiSelect: true,
		anyMatch: true
	};
	Ext.applyIf(config, defaults);
	return createComboTree(config);
};

/**
 * Creates fieldContainer with docTypes tree combobox
 * @param	{Object}	[config]
 * @returns	{Object}	UI.components.ComboboxTreeField instance
 */
export const createDocumentTypeFilterSelectForGrid = function (config: AnyObject) {
	config = 'object' == typeof config ? config : {};
	const defaults = {
		fieldLabel: edi.i18n.getMessage('documents.form.type'),
		emptyText: ''
	};
	Ext.applyIf(config, defaults);

	return createComboDocType(config);
};
