// @ts-ignore
import { PTYPE_APPEND_ICONBUTTON } from '@UIkit/plugins/AppendSibling/AppendIconButton';
// @ts-ignore
import { createLabel } from '@UIkit/components/fields';
// @ts-ignore
import { createFieldBlock, FIELD_BLOCK_CLS } from '@UIkit/components/panels';
// @ts-ignore
import { showConfirm, showInfo } from '@UIkit/components/modal/MessageBox';
import { LinkDocumentsToPackageWindow } from './linkDocumentToPackageWindow';
import { documentPackageApi, DocumentPackageHeader, PackageType } from './entities';
import { ActionRuleCheckOptions } from '@App/js/action';
import { MassDocumentActions } from '@Edi/methods/massDocumentActions';
import { createContainer } from '@Components/miscComponents';
import { realtimeEvents } from '@App/js/realtime_messages';
import { RealtimeMessage, SendRequestResponse } from '@App/js/definitions/request';

export const documentPackageMethods = {
	eventsObserver: new Ext.util.Observable(),
	ALLOWED_DOCUMENT_TYPES: [
		edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED,
		edi.constants.DOCUMENT_TYPES.DSF_UNKNOWN,
		edi.constants.DOCUMENT_TYPES.DSF_INVOICE,
		edi.constants.DOCUMENT_TYPES.DSF_PRICAT,
		edi.constants.DOCUMENT_TYPES.DSF_SUPPLEMENTARY_AGREEMENT
	] as string[],
	PACKAGE_STATES_FOR_RENDERER: {
		DRAFT: 'DRAFT',
		SENT: 'SENT',
		PROCESSING: 'PROCESSING',
		ON_ANNUL: 'ON_ANNUL',
		CANCELED: 'CANCELED',
		REJECTED: 'REJECTED',
		COMPLETED: 'COMPLETED',
		default: 'PROCESSING'
	} as { [state: string]: string },
	packageStateRenderer: function (packageHeader: DocumentPackageHeader): string {
		const state = packageHeader.state;
		const map = documentPackageMethods.PACKAGE_STATES_FOR_RENDERER;
		const stateByMap = map[state] ?? map.default;
		return edi.renderers.status(Object.assign({}, packageHeader, { state: stateByMap }));
	},
	/**
	 * Проверяет может ли документ быть использован в пакетах
	 */
	isPackagingAllowed: function (docType: string): boolean {
		return documentPackageMethods.ALLOWED_DOCUMENT_TYPES.includes(docType);
	},
	/**
	 * Условие для actionRules, которое проверяет, что документ не находится в закрытом пакете, в этом случае действие
	 * блочится, т.к. должно выполняться из пакета одинаково для всех документов
	 */
	isDocActionBlocked: function (checkOptions: ActionRuleCheckOptions): boolean {
		if (edi.constants.ALLOW_ENCLOSED_PACKAGE_ACTIONS === true) return false;

		const docHeader = checkOptions.record.getData();
		const docInClosedPackage =
			!!docHeader.packageId && edi.utils.getAttributeByName(docHeader.attributes, 'packageEnclosed') === 'true';
		return (
			checkOptions.actionFromPackageModule !== true &&
			docInClosedPackage &&
			documentPackageMethods.isPackagingAllowed(checkOptions.docType)
		);
	},
	/**
	 * Открывает окно для добавления документов в существующий пакет
	 */
	addDocsToPackage: function (
		docIds: number[],
		toOrgId: number,
		success?: (resp: AnyObject) => {},
		failure?: (resp: AnyObject) => {}
	) {
		new LinkDocumentsToPackageWindow({
			documents: docIds,
			toOrgId,
			success,
			failure
		}).show();
	},
	/**
	 * Открывает модуль создания пакета с уже добавленным документом
	 */
	createPackageWithDocuments(docHeaders: DocumentHeader[]) {
		edi.core.openModule('create.documentPackage', { addConf: { preselectedDocuments: docHeaders } });
	},
	/**
	 * Отвязывает документ от пакета по айди документа
	 */
	unlinkDocFromPackage: function (
		packageId: number,
		docId: number,
		success?: (resp: AnyObject) => {},
		failure?: (resp: AnyObject) => {}
	) {
		showConfirm({
			msgText: 'documents.package.delete.document.confirm',
			success: async function () {
				const result = await documentPackageApi.unlinkDocuments(packageId, [docId]);
				if (result.success) {
					documentPackageMethods.eventsObserver.fireEvent('changeDocuments', { packageId });
					success?.(result.response);
				} else {
					failure =
						'function' == typeof failure
							? failure
							: edi.rest.getErrorHandler('document.error.in.package.delete');
					failure?.(result.response);
				}
			}
		});
	},
	createPackageTypeHintPlugin: function (refEl: string): AnyObject {
		return {
			ptype: PTYPE_APPEND_ICONBUTTON,
			refEl: refEl,
			hintConfig: {
				popupAlign: 'r',
				layout: {
					type: 'grid',
					gap: [16, 16]
				},
				items: [
					createLabel({
						text: edi.i18n.getMessage('documentPackage.type.hint.title'),
						typography: 'heading_01'
					}),
					createFieldBlock({
						cls: FIELD_BLOCK_CLS.small,
						title: edi.i18n.getMessage('documentPackage.type.hint.title.opened'),
						items: [
							createLabel({
								text: edi.i18n.getMessage('documentPackage.type.hint.body.opened'),
								typography: 'body-long_01'
							})
						]
					}),
					createFieldBlock({
						cls: FIELD_BLOCK_CLS.small,
						title: edi.i18n.getMessage('documentPackage.type.hint.title.closed'),
						items: [
							createLabel({
								text: edi.i18n.getMessage('documentPackage.type.hint.body.closed'),
								typography: 'body-long_01'
							})
						]
					})
				]
			}
		};
	},

	rejectDocs(
		docRecords: ExtRecord<DocumentHeader>[],
		packageData: DocumentPackageHeader,
		massDocumentActions: MassDocumentActions,
		moduleGrid: ExtComponent | null,
		moduleData: ModuleData<DocumentPackageHeader>,
		reason?: string
	): Promise<{ notProcessed: ExtRecord<DocumentHeader>[]; reason?: string }> {
		return new Promise((resolve) => {
			const records = docRecords.filter((rec) => {
				const docCheckOptions = edi.action.getDocumentData(rec, null, {
					actionFromPackageModule: true
				});
				return edi.action.isAvailable(edi.constants.DOCUMENT_ACTIONS.REJECT, docCheckOptions);
			});
			if (!records.length) {
				resolve({ notProcessed: [], reason: '' });
				return;
			}

			const rejectConf = massDocumentActions.getRejectActionConfig(moduleGrid, reason);
			const originalComplete: Function = rejectConf.completeCallback;
			rejectConf.completeCallback = function (
				signedDocs: ExtRecord<DocumentHeader>[],
				notSignedDocs: ExtRecord<DocumentHeader>[]
			) {
				//пересохраняем серт и мчд, т.к. будем вызывать еще и Запросить уточнение сразу после отклонения
				const notProcessed = [...notSignedDocs];
				const reason = rejectConf.getReason();
				const certHandler = Ext.clone(massDocumentActions.certificateHandler);
				const poaHandler = Ext.clone(massDocumentActions.poaHandler);
				originalComplete(signedDocs, notSignedDocs);
				massDocumentActions.certificateHandler.set(
					certHandler._selectedCertificate,
					certHandler._selectedCertObj,
					certHandler._isLocalCert
				);
				massDocumentActions.poaHandler.set(poaHandler._selectedPoa);
				massDocumentActions.poaHandler.setPoaConfirmCheck(poaHandler._poaConfirmChecked);
				documentPackageMethods.eventsObserver.fireEvent('update', { packageId: packageData.id });
				resolve({
					notProcessed,
					reason
				});
			};

			edi.document.actions.documentGridActionProcess({
				action: edi.constants.DOCUMENT_ACTIONS.REJECT,
				moduleData: moduleData,
				grid: moduleGrid,
				initialData: records,
				data: records,
				dontShowNotProccessed: true,
				skipInfoAndConfirm: true,
				additionalCheckOptions: {
					actionFromPackageModule: true
				},
				...rejectConf
			});
		});
	},

	clarifyDocs(
		docRecords: ExtRecord<DocumentHeader>[],
		reason: string | undefined,
		packageData: DocumentPackageHeader,
		massDocumentActions: MassDocumentActions,
		moduleGrid: ExtComponent | null,
		moduleData: ModuleData<DocumentPackageHeader>,
		skipOriginalComplete?: boolean
	): Promise<{ notProcessed: ExtRecord<DocumentHeader>[]; reason?: string }> {
		skipOriginalComplete = !!skipOriginalComplete;
		return new Promise((resolve) => {
			const records = docRecords.filter((rec) => {
				const docCheckOptions = edi.action.getDocumentData(rec, null, {
					actionFromPackageModule: true
				});
				return edi.action.isAvailable(edi.constants.DOCUMENT_ACTIONS.CLARIFY, docCheckOptions);
			});
			if (!records.length) {
				resolve({ notProcessed: [], reason: '' });
				return;
			}
			const clarifyConfOpts =
				packageData.type === PackageType.ENCLOSED
					? {
							additionalPostProps: { fromPackage: true }
					  }
					: undefined;
			const clarifyConf = massDocumentActions.getClarifyActionConfig(reason, clarifyConfOpts);
			const originalComplete: Function = clarifyConf.completeCallback;
			clarifyConf.completeCallback = function (
				signedDocs: ExtRecord<DocumentHeader>[],
				notSignedDocs: ExtRecord<DocumentHeader>[]
			) {
				const notProcessed = [...notSignedDocs];
				const reason = clarifyConf.getReason();
				if (!skipOriginalComplete) {
					originalComplete(signedDocs, notSignedDocs);
				}
				documentPackageMethods.eventsObserver.fireEvent('update', { packageId: packageData.id });
				resolve({ notProcessed, reason });
			};
			clarifyConf.confirmAction = false;

			edi.document.actions.documentGridActionProcess({
				action: edi.constants.DOCUMENT_ACTIONS.CLARIFY,
				moduleData: moduleData,
				grid: moduleGrid,
				initialData: records,
				data: records,
				dontShowNotProccessed: true,
				skipInfoAndConfirm: true,
				additionalCheckOptions: {
					actionFromPackageModule: true
				},
				...clarifyConf
			});
		});
	},
	packagesGroupPrint({ grid, maskElement }: { grid: ExtComponent; maskElement?: ExtComponent }) {
		const gridSelModel = grid.getSelectionModel();
		const dataSelected = gridSelModel.getSelection() as ExtRecord<DocumentPackageHeader>[];

		const setLoading = (loading: boolean) => {
			if (maskElement && !maskElement.destroyed) maskElement.setLoading(loading);
		};

		const failure = edi.document.actions.defaultFailureHandler(
			maskElement,
			'documents.report.request.error',
			function () {
				setLoading(false);
			}
		);

		let reportIdS: {
				[reportId: string]: {
					processed: boolean;
				};
			} | null = null,
			timer: number,
			tickModal: ExtComponent,
			tickModalMsgLabel: ExtComponent;

		const clearTimers = function () {
			timer ? clearInterval(timer) : null;
			if (tickModal) {
				tickModal.close();
			}
		};

		const onGroupPrintFormComplete = function (msgData: RealtimeMessage) {
			if (!reportIdS) return;

			const { meta, content } = msgData?.data || {};
			const reportId = meta?.mainObjId;
			if (reportIdS[reportId]) reportIdS[reportId].processed = true;
			if (!Object.values(reportIdS).some((reportId) => !reportId.processed)) {
				clearTimers();
				documentPackageMethods.groupReportDownloadHandler({
					id: Object.keys(reportIdS).map(Number)
				});
				reportIdS = null;
			}
		};

		let seconds = 0;
		const tick = function () {
			tickModal = showInfo({
				items: createContainer({
					margin: '8 24',
					items: [
						(tickModalMsgLabel = createLabel({
							html: edi.i18n.getMessage('print.report.requested', [seconds++]),
							typography: 'body-short_01'
						}))
					]
				}),
				callback: function () {
					timer ? clearInterval(timer) : null;
					realtimeEvents.un('groupPackagePrintFormComplete', onGroupPrintFormComplete);
				}
			}) as ExtComponent;
		};
		const updateTickModalText = function () {
			tickModalMsgLabel?.setText(edi.i18n.getMessage('print.report.requested', [seconds++]), false);
		};

		realtimeEvents.on('groupPackagePrintFormComplete', onGroupPrintFormComplete);

		edi.rest.sendRequest(
			edi.rest.services.REPORT.GROUP.ON_PACKAGE_LIST.POST,
			'POST',
			Ext.encode(dataSelected.map((record: ExtRecord<DocumentPackageHeader>) => record.get('id'))),
			function (responseData: SendRequestResponse) {
				reportIdS = (responseData.items ?? []).reduce((ids, reportId) => {
					ids[reportId] = {
						processed: false
					};
					return ids;
				}, {});
				for (let i = 0; i < dataSelected.length; i++) {
					gridSelModel?.deselect(dataSelected[i]);
				}
				setLoading(false);
			},
			failure,
			function () {
				if (reportIdS) {
					tick();
					timer = setInterval(updateTickModalText, edi.constants.PRINT_STATUS_CHECK_DELAY);
				}
			}
		);
	},
	groupReportDownloadHandler({ id }: { id: number | number[] }) {
		return edi.rest.downloadFileAsync({
			url: edi.rest.services.REPORT.GROUP.ON_PACKAGE_LIST.EXPORT.POST,
			method: 'POST',
			payload: Ext.encode(Array.isArray(id) ? id : [id]),
			fail: (data: AnyObject, opts: AnyObject, resp: AnyObject) => {
				edi.rest.getErrorHandler(
					resp.timedout === true ? 'file.download.error.timeout' : 'file.download.error'
				)(data);
			}
		});
	}
};
edi.events.documentPackage = documentPackageMethods.eventsObserver;

edi.constants.DOCUMENT_GROUP_REPORT_PRINT_CONFIG.push({
	type: 'PACKAGE_DOCUMENTS',
	downloadHandler: documentPackageMethods.groupReportDownloadHandler
});
