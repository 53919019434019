export const DOCUMENT_PACKAGES_MODEL_CONFIG_NAME = 'DOCUMENT_PACKAGES';
export const DOCUMENT_PACKAGES_DETAILS_WINDOW_MODEL_CONFIG_NAME = 'DOCUMENT_PACKAGES_DETAILS_WINDOW';
edi.models.setModels({
	[DOCUMENT_PACKAGES_MODEL_CONFIG_NAME]: {
		fields: [
			{
				name: 'id',
				type: 'number'
			},
			{
				name: 'isPackage'
			},
			{
				name: 'creationDate',
				type: 'string'
			},
			{
				name: 'modifyDate',
				type: 'string'
			},
			{
				name: 'fromOrg'
				//type: 'object'
			},
			{
				name: 'toOrg'
				//type: 'object'
			},
			{
				name: 'documents'
				//type: 'array'
			}
		],
		idProperty: 'iln'
	},
	[DOCUMENT_PACKAGES_DETAILS_WINDOW_MODEL_CONFIG_NAME]: {
		extend: 'edi.models.DOCUMENT',
		fields: []
	}
});
