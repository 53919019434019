import { createModalTabForm } from '@Core/specialComponents/modals';
import { MODAL_SIZE } from '@UIkit/components/modal';
import { createContainer, createFieldBlock, FIELD_BLOCK_CLS } from '@UIkit/components/panels';
import { createCheckbox, createCombo, createTextField } from '@UIkit/components/fields';

/**
 *
 * @param	{Object}	[data]
 * @param	{Function}	[callback]
 * @param	{Object}	[options]
 * @param	{Boolean}	[isDELCAT]
 * @returns	{Object}	Ext.window.Window instance
 */
const createLocationCatalogItemModal = function (data, callback, options, isDELCAT) {
	const isEdit = !!data;
	data = data ? data : {};
	const fieldConf = {
		valueSrc: data,
		readOnly: false,
		disabled: options?.readOnly,
		...options
	};
	const fieldBlockConf = {
		cls: FIELD_BLOCK_CLS.small
	};
	const containerConf = {
		padding: '24 10 0 24'
	};
	const tabConf = {
		padding: 0,
		layout: 'auto'
	};

	const basics = createContainer({
		layout: {
			type: 'grid',
			gap: 16,
			ignoreHiddenElements: true,
			area: [8, [2, 6], 6, 6, 6, 6, [2, 2, 2, 2], 8, [3, 3], [3, 3, 3], [8, 4]]
		},
		items: [
			createFieldBlock({
				title: edi.i18n.getMessage('column.country'),
				items: [
					createCombo({
						name: 'country',
						store: edi.stores.initValidCountryFullStore(),
						valueField: 'iso_2',
						value: !data.country ? 'RU' : undefined,
						displayField: 'name',
						listeners: {
							select: function (comp, records) {
								const record = records && undefined != records.length ? records[0] : records;
								const form = comp.up('form');
								const fields = edi.utils.getFormFields(form);
								if (record && record.get && record.get('iso_2') == 'RU') {
									if (!fieldConf.readOnly) {
										fields['state'].getFieldBlock().show();
										fields['state'].setDisabled(false);
									}
									if (fields['stateCode']) {
										fields['stateCode'].getFieldBlock().hide();
										fields['stateCode'].setDisabled(true);
									}
								} else {
									if (!fieldConf.readOnly) {
										fields['stateCode'].getFieldBlock().show();
										fields['stateCode'].setDisabled(false);
										fields['stateCode'].setValue('');
									}
									if (fields['state']) {
										fields['state'].getFieldBlock().hide();
										fields['state'].setDisabled(true);
									}
								}
							}
						},
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('company.zip'),
				items: [
					createTextField({
						name: 'postalCode',
						maxLength: 9,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.state'),
				items: [
					createCombo({
						name: 'state',
						store: edi.stores.initRegionsStore(),
						valueField: 'id',
						displayField: 'display_name',
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.state.code'),
				items: [
					createTextField({
						name: 'stateCode',
						maxLength: 9,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.district'),
				items: [
					createTextField({
						name: 'district',
						maxLength: 140,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.city.name'),
				items: [
					createTextField({
						name: 'cityName',
						maxLength: 140,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.locality'),
				items: [
					createTextField({
						name: 'locality',
						maxLength: 140,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.street.and.number'),
				items: [
					createTextField({
						name: 'streetAndNumber',
						maxLength: 255,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.house.number'),
				items: [
					createTextField({
						name: 'houseNumber',
						maxLength: 9,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.housing'),
				items: [
					createTextField({
						name: 'housing',
						maxLength: 9,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.room.number'),
				items: [
					createTextField({
						name: 'roomNumber',
						maxLength: 9,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('company.flat'),
				items: [
					createTextField({
						name: 'apartment',
						maxLength: 20,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.name'),
				items: [
					createTextField({
						name: 'name',
						maxLength: 175,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.email'),
				items: [
					createTextField({
						name: 'email',
						maxLength: 350,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.phone.number'),
				items: [
					createTextField({
						name: 'phoneNumber',
						maxLength: 175,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.tax.id'),
				items: [
					createTextField({
						name: 'taxID',
						maxLength: 35,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.iln'),
				items: [
					createTextField({
						name: 'iln',
						allowBlank: false,
						regex: edi.constants.VALIDATORS.ILN,
						maskRe: /\d/i,
						enforceMaxLength: true,
						minLength: 13,
						maxLength: 13,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.previous.iln'),
				items: [
					createTextField({
						name: 'previousILN',
						regex: edi.constants.VALIDATORS.ILN,
						maskRe: /\d/i,
						enforceMaxLength: true,
						minLength: 13,
						maxLength: 13,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.territory.name'),
				items: [
					createTextField({
						name: 'territoryName',
						maxLength: 175,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.utilization.register.number'),
				items: [
					createTextField({
						name: 'utilizationRegisterNumber',
						maxLength: 35,
						...fieldConf
					})
				],
				...fieldBlockConf
			})
		],
		...containerConf
	});

	const codes = createContainer({
		layout: {
			type: 'grid',
			gap: 16,
			area: [[6, 6], [6, 6], [6, 6], 6]
		},
		items: [
			createFieldBlock({
				title: edi.i18n.getMessage('column.code.by.receiver'),
				items: [
					createTextField({
						name: 'codeByReceiver',
						maxLength: 35,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.code.by.sender'),
				items: [
					createTextField({
						name: 'codeBySender',
						maxLength: 35,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.group.code.by.reciever'),
				items: [
					createTextField({
						name: 'groupCodeByReciever',
						maxLength: 35,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.group.code.by.sender'),
				items: [
					createTextField({
						name: 'groupCodeBySender',
						maxLength: 35,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.seller.location.code'),
				items: [
					createTextField({
						name: 'sellerLocationCode',
						maxLength: 35,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.territory.code.by.sender'),
				items: [
					createTextField({
						name: 'territoryCodeBySender',
						maxLength: 35,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.tax.registration.reason.code'),
				items: [
					createTextField({
						name: 'taxRegistrationReasonCode',
						maxLength: 35,
						...fieldConf
					})
				],
				...fieldBlockConf
			})
		],
		...containerConf
	});

	const headquarters = createContainer({
		layout: {
			type: 'grid',
			gap: 16,
			area: [
				[6, 6],
				[6, 6],
				[6, 6]
			]
		},
		items: [
			createFieldBlock({
				title: edi.i18n.getMessage('column.headquarters.name'),
				items: [
					createTextField({
						name: 'headquarters.name',
						requiredFields: ['headquarters.iln'],
						maxLength: 175,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.headquarters.utilization.register.number'),
				items: [
					createTextField({
						name: 'headquarters.utilizationRegisterNumber',
						requiredFields: ['headquarters.iln'],
						maxLength: 35,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.headquarters.iln'),
				items: [
					createTextField({
						name: 'headquarters.iln',
						regex: edi.constants.VALIDATORS.ILN,
						maskRe: /\d/i,
						enforceMaxLength: true,
						minLength: 13,
						maxLength: 13,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.headquarters.tax.id'),
				items: [
					createTextField({
						name: 'headquarters.taxID',
						requiredFields: ['headquarters.iln'],
						maxLength: 35,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.headquarters.code.by.sender'),
				items: [
					createTextField({
						name: 'headquarters.codeBySender',
						requiredFields: ['headquarters.iln'],
						maxLength: 35,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.headquarters.code.by.receiver'),
				items: [
					createTextField({
						name: 'headquarters.codeByReceiver',
						requiredFields: ['headquarters.iln'],
						maxLength: 35,
						...fieldConf
					})
				],
				...fieldBlockConf
			})
		],
		...containerConf
	});

	const shipFrom = createContainer({
		layout: {
			type: 'grid',
			gap: 16,
			area: [
				[6, 3, 3],
				[6, 6]
			]
		},
		items: [
			createFieldBlock({
				title: edi.i18n.getMessage('column.ship.from.name'),
				items: [
					createTextField({
						name: 'shipFrom.name',
						maxLength: 175,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.ship.from.iln'),
				items: [
					createTextField({
						name: 'shipFrom.iln',
						regex: edi.constants.VALIDATORS.ILN,
						maskRe: /\d/i,
						enforceMaxLength: true,
						minLength: 13,
						maxLength: 13,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.ship.from.tax.id'),
				items: [
					createTextField({
						name: 'shipFrom.taxID',
						maxLength: 35,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.ship.from.code.by.sender'),
				items: [
					createTextField({
						name: 'shipFrom.codeBySender',
						maxLength: 35,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.ship.from.utilization.register.number'),
				items: [
					createTextField({
						name: 'shipFrom.utilizationRegisterNumber',
						maxLength: 35,
						...fieldConf
					})
				],
				...fieldBlockConf
			})
		],
		...containerConf
	});

	const additional = createContainer({
		layout: {
			type: 'grid',
			gap: 16,
			area: [6, 6, [3, 3, 3], [6, 6], [6, 6], [6, 6], [6, 6], [6, 6]]
		},
		items: [
			createFieldBlock({
				title: edi.i18n.getMessage('column.activity.status'),
				items: [
					createCombo({
						name: 'activityStatus',
						store: edi.stores.createSimpleInlineStore(
							['ACTIVE', 'INACTIVE'],
							(id) => edi.i18n.getMessage('activity.status.' + id),
							true
						),
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.contact.person'),
				items: [
					createTextField({
						name: 'contactPerson',
						maxLength: 175,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.credit.amount'),
				items: [
					createTextField({
						name: 'creditAmount',
						type: 'number',
						maxLength: 12,
						allowDecimals: true,
						decimalPrecision: 4,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.discount'),
				items: [
					createTextField({
						name: 'discount',
						type: 'number',
						maxLength: 12,
						allowDecimals: true,
						decimalPrecision: 2,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.discount.group'),
				items: [
					createTextField({
						name: 'discountGroup',
						maxLength: 35,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.supplier.unit.of.measure'),
				items: [
					createTextField({
						name: 'supplierUnitOfMeasure',
						maxLength: 10,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.allowed.unit.of.measure'),
				items: [
					createTextField({
						name: 'allowedUnitOfMeasure',
						maxLength: 6,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createCheckbox({
				boxLabel: edi.i18n.getMessage('column.allow.backorder'),
				name: 'allowBackorder',
				uncheckedValue: 0,
				inputValue: 1,
				...fieldConf
			}),
			createCheckbox({
				boxLabel: edi.i18n.getMessage('column.allow.view.stock'),
				name: 'allowViewStock',
				uncheckedValue: 0,
				inputValue: 1,
				...fieldConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.account.number'),
				items: [
					createTextField({
						name: 'accountNumber',
						maxLength: 35,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),

			createFieldBlock({
				title: edi.i18n.getMessage('column.amount.due'),
				items: [
					createTextField({
						name: 'amountDue',
						type: 'number',
						maxLength: 12,
						allowDecimals: true,
						decimalPrecision: 4,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.assortment.group'),
				items: [
					createTextField({
						name: 'assortmentGroup',
						maxLength: 35,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.assortment.type'),
				items: [
					createTextField({
						name: 'assortmentType',
						maxLength: 10,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			isDELCAT
				? createFieldBlock({
						title: edi.i18n.getMessage('column.latitude'),
						items: [
							createTextField({
								name: 'latitude',
								maxLength: 20,
								...fieldConf
							})
						],
						...fieldBlockConf
				  })
				: null,
			isDELCAT
				? createFieldBlock({
						title: edi.i18n.getMessage('column.longitude'),
						items: [
							createTextField({
								name: 'longitude',
								maxLength: 20,
								...fieldConf
							})
						],
						...fieldBlockConf
				  })
				: null
		],
		...containerConf
	});

	const other = createContainer({
		layout: {
			type: 'grid',
			gap: 16,
			area: [
				[3, 3, 3, 3],
				[4, 4]
			]
		},
		items: [
			createFieldBlock({
				title: edi.i18n.getMessage('column.id'),
				items: [
					createTextField({
						name: 'id',
						...fieldConf,
						disabled: true
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.type'),
				items: [
					createTextField({
						name: 'type',
						maxLength: 3,
						...fieldConf,
						disabled: true
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.line.type'),
				items: [
					createTextField({
						name: 'lineType',
						maxLength: 3,
						...fieldConf,
						disabled: true
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.system.id'),
				items: [
					createTextField({
						name: 'systemID',
						maxLength: 35,
						...fieldConf,
						disabled: true
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.creation.date'),
				items: [
					createTextField({
						name: 'creationDate',
						...fieldConf,
						disabled: true,
						valueSrc: undefined,
						value: edi.utils.formatDate(data?.creationDate, edi.constants.DATE_FORMAT.FNS)
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.modify.date'),
				items: [
					createTextField({
						name: 'modifyDate',
						...fieldConf,
						disabled: true,
						valueSrc: undefined,
						value: edi.utils.formatDate(data?.modifyDate, edi.constants.DATE_FORMAT.FNS)
					})
				],
				...fieldBlockConf
			})
		],
		...containerConf
	});

	const tabs = [
		{
			title: edi.i18n.getMessage('tab.title.basic'),
			items: [basics],
			...tabConf
		},
		{
			title: edi.i18n.getMessage('tab.title.codes'),
			items: [codes],
			...tabConf
		},
		{
			title: edi.i18n.getMessage('tab.title.headquarters'),
			items: [headquarters],
			...tabConf
		},
		{
			title: edi.i18n.getMessage('tab.title.ship.from'),
			items: [shipFrom],
			allowBlank: true,
			...tabConf
		},
		{
			title: edi.i18n.getMessage('tab.title.additional'),
			items: [additional],
			...tabConf
		}
	];
	if (isEdit || fieldConf.readOnly) {
		tabs.push({
			title: edi.i18n.getMessage('tab.title.other'),
			items: [other],
			...tabConf
		});
	}
	const modal = createModalTabForm(
		edi.i18n.getMessage('catalog.delcat.item.title'),
		tabs,
		function (data) {
			'function' == typeof callback ? callback(data) : null;
		},
		null,
		isEdit,
		{
			isFocusOnInvalidField: true,
			width: MODAL_SIZE.widthLarge,
			buttons: fieldConf.readOnly ? [] : undefined
		}
	);
	modal.show();
	return modal;
};

export { createLocationCatalogItemModal };
