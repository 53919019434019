import { GRID_COMMON_COLUMN_CONFIG_NAME, GRID_REMOVED_COLUMN_CONFIG_NAME } from '@Edi/modules/documentGrids/columns';

edi.columns.addColumns({
	cargoInfoByAccompanyingDocuments: {
		nameCargo: {
			text: 'document.torg2.cargo.name',
			dataIndex: 'nameCargo',
			flex: 2
		},
		spaceCount: {
			text: 'document.torg2.count.place',
			dataIndex: 'spaceCount',
			flex: 1
		},
		productOkei: {
			text: 'document.torg2.ok',
			dataIndex: 'productOkei',
			flex: 1
		},
		unitOfMeasure: {
			text: 'product.unit.name',
			dataIndex: 'unitOfMeasure',
			// renderer: "UnitOfMeasure",
			renderer: function (value, meta, rec) {
				// дополненный рендерер "UnitOfMeasure" (не проверялся id)
				var ret = value,
					record;
				if ('function' == typeof edi.stores.initOkeiStore) {
					record = edi.stores
						.initOkeiStore()
						.findRecord('name_international', value, undefined, false, false, true);
					if (!record) {
						record = edi.stores.initOkeiStore().findRecord('name', value, undefined, false, false, true);
					}
					if (!record) {
						record = edi.stores.initOkeiStore().findRecord('name_international', value);
					}
					if (!record) {
						record = edi.stores.initOkeiStore().findRecord('id', value);
					}
					if (!record) {
						record = edi.stores.initOkeiStore().findRecord('id', rec.get('productOkei'));
					}
					if (record) {
						ret = record.get('name');
					}
				}
				return ret;
			},
			flex: 1
		},
		packType: {
			text: 'document.torg2.view.packaging',
			dataIndex: 'packType',
			flex: 1
		},
		sealMark: {
			text: 'document.torg2.sealing.mark',
			dataIndex: 'sealMark',
			flex: 1
		},
		weightCargoStartGross: {
			text: 'document.torg2.sent.gross.mass',
			flex: 1,
			renderer: function (value, meta, record) {
				return record.get('weightCargoStart')?.gross ? record.get('weightCargoStart')?.gross : '';
			},
			dataIndex: 'startGross'
		},
		weightCargoStartNet: {
			text: 'document.torg2.sent.net.mass',
			flex: 1,
			renderer: function (value, meta, record) {
				return record.get('weightCargoStart')?.net ? record.get('weightCargoStart')?.net : '';
			},
			dataIndex: 'startNet'
		},
		weightCargoActualGross: {
			text: 'document.torg2.received.gross.mass',
			flex: 1,
			renderer: function (value, meta, record) {
				return record.get('weightCargoActual')?.gross ? record.get('weightCargoActual')?.gross : '';
			},
			dataIndex: 'gross'
		},
		weightCargoActualNet: {
			text: 'document.torg2.received.net.mass',
			flex: 1,
			renderer: function (value, meta, record) {
				return record.get('weightCargoActual')?.net ? record.get('weightCargoActual')?.net : '';
			},
			dataIndex: 'net'
		},
		identifier: {
			text: 'document.torg2.identifier',
			flex: 1,
			renderer: function (value, meta, record) {
				return record.get('textInfo')?.identifier ? record.get('textInfo')?.identifier : '';
			},
			dataIndex: 'textInfoIdentifier'
		},
		value: {
			text: 'document.torg2.value',
			flex: 1,
			renderer: function (value, meta, record) {
				return record.get('textInfo')?.value ? record.get('textInfo')?.value : '';
			},
			dataIndex: 'textInfoValue'
		}
	},
	torg_2_document_lines: {
		lineNumber: {
			text: 'nr',
			dataIndex: 'LineNumber',
			renderer: function (value, meta) {
				return value || meta.rowIndex + 1;
			},
			flex: 0.5
		},
		artikul: {
			text: 'column.artikul',
			flex: 1,
			renderer: function (value, meta, record) {
				return record.get('values')?.productReference ? record.get('values')?.productReference : '';
			},
			dataIndex: 'productReference'
		},
		productName: {
			text: 'document.torg2.cargo.name',
			flex: 2,
			renderer: function (value, meta, record) {
				return record.get('values')?.valueName ? record.get('values')?.valueName : '';
			},
			dataIndex: 'valueName'
		},
		unitOfMeasure: {
			text: 'product.unit.name',
			flex: 1,
			dataIndex: 'unitOfMeasure',
			renderer: function (value, meta, rec) {
				// дополненный рендерер "UnitOfMeasure" (не проверялся id)
				var ret = value,
					record;
				if ('function' == typeof edi.stores.initOkeiStore) {
					record = edi.stores
						.initOkeiStore()
						.findRecord('name_international', value, undefined, false, false, true);
					if (!record) {
						record = edi.stores.initOkeiStore().findRecord('name', value, undefined, false, false, true);
					}
					if (!record) {
						record = edi.stores.initOkeiStore().findRecord('name_international', value);
					}
					if (!record) {
						record = edi.stores.initOkeiStore().findRecord('id', value);
					}
					if (!record) {
						record = edi.stores.initOkeiStore().findRecord('id', rec.get('productOkei'));
					}
					if (record) {
						ret = record.get('name');
					}
				}
				return ret;
			}
		},
		unitPrice: {
			text: 'document.torg2.column.unit.price',
			flex: 1,
			renderer: function (value, meta, record) {
				return record.get('senderInfo')?.cost ? record.get('senderInfo')?.cost : '';
			},
			dataIndex: 'senderCost'
		},
		actualCost: {
			text: 'document.part2.column.actual.cost',
			flex: 1,
			renderer: function (value, meta, record) {
				return value?.cost ? value?.cost : '';
			},
			dataIndex: 'actualInfo'
		},
		fnsTaxRate: {
			text: 'document.torg2.column.fns.tax.rate',
			flex: 1,
			renderer: function (value, meta, record) {
				return record.get('senderInfo')?.rateNDS ? record.get('senderInfo')?.rateNDS : '';
			},
			dataIndex: 'senderRateNDS'
		},
		productCount: {
			text: 'column.amount',
			flex: 1,
			renderer: function (value, meta, record) {
				return record.get('senderInfo')?.count ? record.get('senderInfo')?.count : '';
			},
			dataIndex: 'senderCount'
		},
		countToReturn: {
			text: 'column.amount',
			flex: 1,
			columns: {
				countToReturnInFact: {
					text: 'document.torg2.column.count.to.return.in.fact',
					renderer: function (value) {
						return value && value.count ? value.count : '';
					},
					dataIndex: 'actualInfo'
				},
				countDamage: {
					text: 'document.dsf.damage',
					renderer: function (value) {
						return value && value.count ? value.count : '';
					},
					dataIndex: 'breakage'
				},
				countMarriage: {
					text: 'document.torg2.column.count.to.return.marriage',
					renderer: function (value) {
						return value && value.count ? value.count : '';
					},
					dataIndex: 'defect'
				},
				countDeficit: {
					text: 'document.dsf.deficit',
					renderer: function (value) {
						return value && value.count ? value.count : '';
					},
					dataIndex: 'shortage'
				},
				countSurplus: {
					text: 'document.torg2.column.count.to.return.surplus',
					renderer: function (value) {
						return value && value.count ? value.count : '';
					},
					dataIndex: 'surplus'
				}
			}
		},
		priceChange: {
			text: 'document.torg2.column.price.rejection',
			flex: 1,
			renderer: function (value, meta, record) {
				var result = '';
				var costDev = record.get('costDeviation');
				if (costDev) {
					var positiveChange =
						costDev.positiveDelta && costDev.positiveDelta.deltaCostWithNDS
							? costDev.positiveDelta.deltaCostWithNDS
							: '';
					var negativeChange =
						costDev.negativeDelta && costDev.negativeDelta.deltaCostWithNDS
							? costDev.negativeDelta.deltaCostWithNDS
							: '';
					var workingChange =
						costDev.delta && costDev.delta.deltaCostWithNDS ? costDev.delta.deltaCostWithNDS : '';
					if (workingChange) {
						result = workingChange;
					} else if (positiveChange) {
						result = positiveChange;
					} else if (negativeChange) {
						result = '-' + negativeChange;
					}
				}
				return result;
			},
			dataIndex: 'deltaCostWithNDS'
		},
		ndsRejection: {
			text: 'document.torg2.delta.cost.nds.rejection',
			flex: 1,
			renderer: function (value, meta, record) {
				var result = '';
				var costDev = record.get('costDeviation');
				if (costDev) {
					var positiveChange =
						costDev.positiveDelta && costDev.positiveDelta.deltaNDS ? costDev.positiveDelta.deltaNDS : '';
					var negativeChange =
						costDev.negativeDelta && costDev.negativeDelta.deltaNDS ? costDev.negativeDelta.deltaNDS : '';
					var workingChange = costDev.delta && costDev.delta.deltaNDS ? costDev.delta.deltaNDS : '';
					if (workingChange) {
						result = workingChange;
					} else if (positiveChange) {
						result = positiveChange;
					} else if (negativeChange) {
						result = '-' + negativeChange;
					}
				}
				return result;
			},
			dataIndex: 'deltaNDS'
		},
		sumChange: {
			text: 'document.torg2.column.sum.rejection',
			flex: 1,
			renderer: function (value, meta, record) {
				var result = '';
				var costDev = record.get('costDeviation');
				if (costDev) {
					var positiveChange =
						costDev.positiveDelta && costDev.positiveDelta.deltaSumNDS
							? costDev.positiveDelta.deltaSumNDS
							: '';
					var negativeChange =
						costDev.negativeDelta && costDev.negativeDelta.deltaSumNDS
							? costDev.negativeDelta.deltaSumNDS
							: '';
					var workingChange = costDev.delta && costDev.delta.deltaSumNDS ? costDev.delta.deltaSumNDS : '';
					if (workingChange) {
						result = workingChange;
					} else if (positiveChange) {
						result = positiveChange;
					} else if (negativeChange) {
						result = '-' + negativeChange;
					}
				}
				return result;
			},
			dataIndex: 'deltaSumNDS'
		}
	},
	fns_document_summary_base: {
		totalWithoutNDS: {
			text: 'column.total.net.amount',
			dataIndex: 'TotalWithoutNDS',
			flex: 1,
			sortable: false,
			hideable: false,
			renderer: 'displayCurrencyDecimals'
		},
		mustPaid: {
			text: 'column.total.tax.amount',
			dataIndex: 'MustPaid',
			flex: 1,
			sortable: false,
			hideable: false,
			renderer: 'cost'
		},
		totalWithNDS: {
			text: 'column.total.gross.amount',
			dataIndex: 'TotalWithNDS',
			flex: 1,
			sortable: false,
			hideable: false,
			renderer: 'displayCurrencyDecimals'
		}
	},
	upd_declaration_info: {
		countryCode: {
			text: 'line.item.country.of.origin',
			dataIndex: 'countryOrigin',
			flex: 1,
			sortable: false,
			hideable: false,
			renderer: function (value, meta, record) {
				var countryStore = edi.stores.initValidCountryFullStore(),
					country = countryStore.findRecord('iso_number_3', value, 0, false, true, true);

				return country ? country.get('display_name') : value;
			}
		},
		number: {
			text: 'column.nomer.td',
			dataIndex: 'number',
			flex: 1,
			sortable: false,
			hideable: false
		}
	}
});

edi.columns.addColumns({
	[GRID_COMMON_COLUMN_CONFIG_NAME]: {
		parents: GRID_COMMON_COLUMN_CONFIG_NAME,
		config: {
			crpt_state: {
				text: 'documents.column.crpt_status',
				dataIndex: 'crpt_status',
				renderer: 'crpt_status',
				htmlEncode: true,
				sortable: false,
				hidden: true,
				order: 324,
				flex: 1.5
			},
			edem_status: {
				text: 'documents.column.edem_status',
				dataIndex: 'edem_status',
				renderer: function (value, meta, record) {
					let statusElems = edi.renderers.edem_status(value, meta, record);
					return statusElems.text;
				},
				htmlEncode: true,
				sortable: false,
				hidden: true,
				order: 327,
				flex: 1
			}
		}
	}
});

edi.columns.addColumns({
	[GRID_REMOVED_COLUMN_CONFIG_NAME]: {
		parents: GRID_REMOVED_COLUMN_CONFIG_NAME,
		config: {
			crpt_state: undefined,
			edem_status: undefined
		}
	}
});

edi.columns.addColumns({
	fns_upd_line_identifyProductToolNumbers: {
		packId: {
			text: 'line.item.identifyProductToolNumbers.packId',
			dataIndex: 'packId',
			renderer: 'desadvLineEAN',
			flex: 1.5
		},
		type: {
			text: 'line.item.identifyProductToolNumbers.type',
			dataIndex: 'type',
			renderer: function (value, metaData) {
				if (!value) {
					return '';
				}
				var retVal = edi.i18n.getMessage('line.item.identifyProductToolNumbers.' + value);
				metaData.tdAttr = 'data-qtip="' + edi.utils.safeString(retVal) + '"';
				return retVal;
			},
			flex: 1
		},
		numbers: {
			text: 'line.item.identifyProductToolNumbers.numbers',
			dataIndex: 'numbers',
			renderer: function (value, metaData, record) {
				var type = record.get('type'),
					data = record.get(type),
					retVal = '';
				if (data && data.length) {
					retVal = data.join(', ');
				}
				retVal = retVal;
				metaData.tdAttr = 'data-qtip="' + edi.utils.safeString(retVal) + '"';
				return retVal;
			},
			flex: 2
		}
	},
	fns_upd_lines: {
		lineNumber: {
			text: 'column.line.number',
			dataIndex: 'lineNumber',
			flex: 0.5
		},
		productName: {
			text: 'column.name',
			tdCls: 'x-grid-cell-inner-portable-row',
			minWidth: 350,
			maxWidth: 500,
			dataIndex: 'productName',
			flex: 2
		},
		count: {
			text: 'column.invoice.quantity',
			dataIndex: 'count',
			flex: 0.75,
			tdCls: 'editable',
			editor: {
				xtype: 'ui-numberfield',
				validator: 'validators.amount15', // used 15 instead of 18 to avoid js rounding large numbers problem
				decimalPrecision: 3,
				allowDecimals: true,
				replaceComa: true,
				allowBlank: false,
				minValue: 1
			},
			renderer: 'decimal'
		},
		okei: {
			text: 'column.unit.of.measure',
			dataIndex: 'productOkei',
			flex: 0.75,
			hidden: true,
			renderer: 'okei'
		},
		presenceGDT: {
			text: 'column.fns.presence.gdt',
			dataIndex: 'customsDeclarationInfo',
			flex: 0.75,
			hidden: true,
			renderer: function (value, column) {
				if (value.length > 0) {
					var numbersGTD = value.map(function (item) {
						return item.number;
					});
					column.tdAttr = 'data-qtip="' + numbersGTD.join('<br/>') + '"';
					column.tdAttr += ` data-qtipFormatted="true"`;
					return edi.i18n.getMessage('answer.exist');
				} else {
					return edi.i18n.getMessage('answer.no');
				}
			}
		},
		price: {
			text: 'column.bill.unit.net.price',
			dataIndex: 'cost',
			flex: 0.75,
			renderer: (value) => edi.utils.displayCurrencyDecimals(value, edi.constants.UPD_PRICE_PRECISION)
		},
		unitGrossPrice: {
			text: 'pricat.product.unitGrossPrice',
			flex: 0.75,
			hidden: true,
			renderer: (value, meta, record) => {
				const textInfos = record.get('textInfos');
				const textInfosUnitGrossPriceIdentifier = 'цена_с_ндс';
				const textInfosUnitGrossPrice = textInfos.find(
					(textInfo) => textInfo.identifier === textInfosUnitGrossPriceIdentifier && textInfo.value
				);
				return edi.utils.displayCurrencyDecimals(
					textInfosUnitGrossPrice?.value,
					edi.constants.UKD_PRICE_PRECISION
				);
			}
		},
		taxSum: {
			text: 'column.tax.amount',
			dataIndex: 'taxSum',
			order: 7,
			flex: 0.75,
			renderer: function (value) {
				return edi.renderers.currencyWithSeparators(edi.utils.displayCurrencyDecimals(value.taxSum || 0, 2));
			}
		},
		tax: {
			text: 'column.fns.tax.rate',
			dataIndex: 'tax',
			order: 7,
			flex: 0.75
		},
		excise: {
			text: 'line.upd.excise',
			dataIndex: 'excise',
			flex: 0.75,
			renderer: function (value) {
				return value.sumExcise !== null
					? edi.utils.displayCurrencyDecimals(value.sumExcise, edi.constants.UPD_PRICE_PRECISION)
					: value.withoutExcise
					? value.withoutExcise
					: 'без акциза';
			}
		},
		costWithoutNDS: {
			text: 'column.net.amount',
			dataIndex: 'costWithoutNDS',
			flex: 0.75,
			renderer: (value) => edi.utils.displayCurrencyDecimals(value, edi.constants.UPD_PRICE_PRECISION)
		},
		costWithNDS: {
			text: 'column.gross.amount',
			dataIndex: 'info',
			flex: 0.75,
			renderer: (value) => edi.utils.displayCurrencyDecimals(value, edi.constants.UPD_PRICE_PRECISION)
		}
	},
	fns_upd_summary: {
		totalWithoutNDS: {
			text: 'column.ordered.unit.net.amount',
			dataIndex: 'totalWithoutNDS',
			flex: 1,
			sortable: false,
			hideable: false,
			renderer: (value) => edi.utils.displayCurrencyDecimals(value, edi.constants.UPD_PRICE_PRECISION)
		},
		mustPaid: {
			text: 'line.item.tax.amount',
			dataIndex: 'totalNDS',
			flex: 1,
			sortable: false,
			hideable: false,
			renderer: (value) => edi.utils.displayCurrencyDecimals(value, edi.constants.UPD_PRICE_PRECISION)
		},
		totalWithNDS: {
			text: 'column.gross.amount',
			dataIndex: 'totalWithNDS',
			flex: 1,
			sortable: false,
			hideable: false,
			renderer: (value) => edi.utils.displayCurrencyDecimals(value, edi.constants.UPD_PRICE_PRECISION)
		}
	},
	fns_ukd_lines_5_01n: {
		lineNumInvoice: {
			xtype: 'treecolumn',
			text: 'column.line.number',
			dataIndex: 'lineNumInvoice',
			sortable: false,
			order: 0,
			flex: 0.5,
			htmlEncode: true,
			renderer: function (value) {
				return Ext.isNumber(value) ? String(value) : ' ';
			}
		},
		productName: {
			text: 'column.name',
			tdCls: 'x-grid-cell-inner-portable-row',
			//minWidth: 350,
			//maxWidth: 500,
			dataIndex: 'ProductName',
			sortable: false,
			flex: 1
		},
		state: {
			text: 'column.product.correct.state',
			dataIndex: 'State',
			sortable: false,
			renderer: function (value) {
				return value ? edi.i18n.getMessage('corrected.product.state.' + value) : '';
			},
			flex: 2
		},
		count: {
			text: 'column.invoice.quantity',
			dataIndex: 'Count',
			sortable: false,
			flex: 0.75,
			editor: {
				xtype: 'ui-numberfield',
				validator: 'validators.amount15WithZero', // used 15 instead of 18 to avoid js rounding large numbers problem
				decimalPrecision: 2,
				allowDecimals: true,
				replaceComa: true,
				allowBlank: false,
				minValue: 0
			},
			renderer: 'decimal'
		},
		productOkei: {
			text: 'column.unit.of.measure',
			dataIndex: 'ProductOkei',
			sortable: false,
			editor: {
				xtype: 'ui-comboboxfield',
				valueField: 'id',
				displayField: 'name_code',
				forceSelection: true,
				anyMatch: true,
				allowBlank: false,
				queryMode: 'local',
				store: {
					model: 'edi.models.OKEI_CODES',
					data: 'data.okei_codes'
				}
			},
			flex: 0.75,
			renderer: 'okei'
		},
		price: {
			text: 'column.fns.invoice.unit.net.price',
			dataIndex: 'Cost',
			sortable: false,
			flex: 0.75,
			editor: {
				xtype: 'ui-numberfield',
				validator: 'validators.amount15', // used 15 instead of 18 to avoid js rounding large numbers problem
				decimalPrecision: 2,
				allowDecimals: true,
				replaceComa: true,
				allowBlank: false,
				minValue: 0,
				currWithZero: true
			},
			renderer: (value) => edi.utils.displayCurrencyDecimals(value, edi.constants.UKD_PRICE_PRECISION)
		},
		taxRate: {
			text: 'column.fns.tax.rate',
			dataIndex: 'TaxRate',
			sortable: false,
			editor: {
				xtype: 'ui-comboboxfield',
				allowBlank: false,
				displayField: 'name',
				forceSelection: true,
				queryMode: 'local',
				store: {
					model: 'edi.models.SIMPLE',
					data: 'data.fnsUsnTaxRates'
				},
				valueField: 'id'
			},
			flex: 0.75
		},
		nDSCost: {
			text: 'column.tax.amount',
			dataIndex: 'NDSCost',
			sortable: false,
			editor: {
				xtype: 'ui-numberfield',
				validator: 'validators.strLen15', // used 15 instead of 18 to avoid js rounding large numbers problem
				decimalPrecision: 2,
				allowDecimals: true,
				replaceComa: true,
				allowBlank: false,
				minValue: 0,
				currWithZero: true
			},
			flex: 0.75,
			renderer: function (value) {
				return edi.renderers.currencyWithSeparators(
					edi.utils.displayCurrencyDecimals(value, edi.constants.UKD_PRICE_PRECISION)
				);
			}
		},
		exciseCost: {
			text: 'column.excise.amount',
			dataIndex: 'ExciseCost',
			sortable: false,
			editor: {
				xtype: 'ui-numberfield',
				validator: 'validators.strLen15', // used 15 instead of 18 to avoid js rounding large numbers problem
				decimalPrecision: 2,
				allowDecimals: true,
				replaceComa: true,
				allowBlank: false,
				minValue: 0
			},
			renderer: function (value, meta, record) {
				var result = value,
					state = record.get('State');
				if (state != 'Increase' && state != 'Decrease') {
					result = value == 0 || value == 'без акциза' ? 'без акциза' : value;
				}

				return result;
			},
			flex: 0.75
		},
		costWithoutNDS: {
			text: 'column.net.amount',
			dataIndex: 'CostWithoutNDS',
			sortable: false,
			editor: {
				xtype: 'ui-numberfield',
				// validator: "validators.amount15", // used 15 instead of 18 to avoid js rounding large numbers problem
				decimalPrecision: 2,
				allowDecimals: true,
				replaceComa: true,
				allowBlank: true,
				currWithZero: true
			},
			flex: 0.75,
			renderer: (value) => edi.utils.displayCurrencyDecimals(value, edi.constants.UKD_PRICE_PRECISION)
		},
		costWithNDS: {
			text: 'column.gross.amount',
			dataIndex: 'CostWithNDS',
			sortable: false,
			editor: {
				xtype: 'ui-numberfield',
				// validator: "validators.amount15", // used 15 instead of 18 to avoid js rounding large numbers problem
				decimalPrecision: 2,
				allowDecimals: true,
				replaceComa: true,
				allowBlank: true,
				currWithZero: true
			},
			flex: 0.75,
			renderer: (value) => edi.utils.displayCurrencyDecimals(value, edi.constants.UKD_PRICE_PRECISION)
		}
	},
	corrected_fns_invoice_lines: {
		lineNumber: {
			xtype: 'treecolumn',
			text: 'column.line.number',
			dataIndex: 'lineNum',
			htmlEncode: true,
			sortable: false,
			order: 0,
			flex: 0.5,
			renderer: function (value) {
				return Ext.isNumber(value) ? String(value) : ' ';
			}
		},
		productName: {
			text: 'column.name',
			tdCls: 'x-grid-cell-inner-portable-row',
			minWidth: 350,
			maxWidth: 500,
			dataIndex: 'ProductName',
			sortable: false,
			flex: 2
		},
		state: {
			text: 'column.product.correct.state',
			dataIndex: 'State',
			sortable: false,
			renderer: function (value) {
				return value ? edi.i18n.getMessage('corrected.product.state.' + value) : '';
			},
			flex: 2
		},
		count: {
			text: 'column.invoice.quantity',
			dataIndex: 'Count',
			sortable: false,
			flex: 0.75,
			field: {
				xtype: 'numberfield',
				validator: 'validators.amount15WithZero', // used 15 instead of 18 to avoid js rounding large numbers problem
				decimalPrecision: 2,
				allowDecimals: true,
				allowBlank: false,
				minValue: 0
			},
			renderer: 'decimal'
		},
		productOkei: {
			text: 'column.unit.of.measure',
			dataIndex: 'ProductOkei',
			sortable: false,
			field: {
				xtype: 'combo',
				valueField: 'id',
				displayField: 'name_code',
				forceSelection: true,
				anyMatch: true,
				allowBlank: false,
				queryMode: 'local',
				store: {
					model: 'edi.models.OKEI_CODES',
					data: 'data.okei_codes'
				}
			},
			flex: 0.75,
			renderer: 'okei'
		},
		price: {
			text: 'column.fns.invoice.unit.net.price',
			dataIndex: 'Cost',
			sortable: false,
			flex: 0.75,
			field: {
				xtype: 'numberfield',
				validator: 'validators.amount15WithZero', // used 15 instead of 18 to avoid js rounding large numbers problem
				decimalPrecision: 2,
				allowDecimals: true,
				allowBlank: false,
				minValue: 0,
				currWithZero: true,
				emptyValue: 0
			},
			renderer: (value) => edi.utils.displayCurrencyDecimals(value, edi.constants.UKD_PRICE_PRECISION)
		},
		taxRate: {
			text: 'column.fns.tax.rate',
			dataIndex: 'TaxRate',
			sortable: false,
			field: {
				xtype: 'combo',
				allowBlank: false,
				displayField: 'name',
				forceSelection: true,
				queryMode: 'local',
				store: {
					model: 'edi.models.SIMPLE',
					data: 'data.fnsTaxRates'
				},
				valueField: 'id'
			},
			flex: 0.75
		},
		nDSCost: {
			text: 'column.tax.amount',
			dataIndex: 'NDSCost',
			sortable: false,
			field: {
				xtype: 'numberfield',
				validator: 'validators.strLen15', // used 15 instead of 18 to avoid js rounding large numbers problem
				decimalPrecision: 2,
				allowDecimals: true,
				allowBlank: false,
				minValue: 0,
				currWithZero: true
			},
			flex: 0.75,
			renderer: function (value) {
				return edi.utils.displayCurrencyDecimals(value, edi.constants.UKD_PRICE_PRECISION);
			}
		},
		exciseCost: {
			text: 'column.excise.amount',
			dataIndex: 'ExciseCost',
			sortable: false,
			field: {
				xtype: 'numberfield',
				validator: 'validators.strLen15', // used 15 instead of 18 to avoid js rounding large numbers problem
				decimalPrecision: 2,
				allowDecimals: true,
				allowBlank: false,
				minValue: 0
			},
			renderer: function (value, meta, record) {
				var result = value,
					state = record.get('State');
				if (state != 'Increase' && state != 'Decrease') {
					result =
						value == 0 || value == 'без акциза'
							? 'без акциза'
							: edi.utils.displayCurrencyDecimals(value, edi.constants.UKD_PRICE_PRECISION);
				}

				return result;
			},
			flex: 0.75
		},
		costWithoutNDS: {
			text: 'column.net.amount',
			dataIndex: 'CostWithoutNDS',
			sortable: false,
			field: {
				xtype: 'numberfield',
				validator: 'validators.amount15WithZero', // used 15 instead of 18 to avoid js rounding large numbers problem
				decimalPrecision: 2,
				allowDecimals: true,
				allowBlank: false,
				minValue: 0,
				currWithZero: true,
				emptyValue: 0
			},
			flex: 0.75,
			renderer: (value) => edi.utils.displayCurrencyDecimals(value, edi.constants.UKD_PRICE_PRECISION)
		},
		costWithNDS: {
			text: 'column.gross.amount',
			dataIndex: 'CostWithNDS',
			sortable: false,
			field: {
				xtype: 'numberfield',
				validator: 'validators.amount15WithZero', // used 15 instead of 18 to avoid js rounding large numbers problem
				decimalPrecision: 2,
				allowDecimals: true,
				allowBlank: false,
				minValue: 0,
				currWithZero: true,
				emptyValue: 0
			},
			flex: 0.75,
			renderer: (value) => edi.utils.displayCurrencyDecimals(value, edi.constants.UKD_PRICE_PRECISION)
		}
	},
	corrected_fns_invoice_summary: {
		state: {
			text: 'column.product.correct.state',
			dataIndex: 'State',
			sortable: false,
			hideable: false,
			flex: 3,
			renderer: function (value) {
				return value ? edi.i18n.getMessage('corrected.product.state.' + value) : '';
			}
		},
		totalWithoutNDS: {
			text: 'column.ordered.unit.net.amount',
			dataIndex: 'TotalWithoutNDS',
			flex: 1,
			sortable: false,
			hideable: false,
			editor: {
				xtype: 'ui-numberfield',
				validator: 'validators.strLen15',
				decimalPrecision: 2,
				allowDecimals: true,
				replaceComa: true,
				allowBlank: false,
				minValue: 0
			},
			renderer: (value) => edi.utils.displayCurrencyDecimals(value, edi.constants.UKD_PRICE_PRECISION)
		},
		mustPaid: {
			text: 'line.item.tax.amount',
			dataIndex: 'MustPaid',
			flex: 1,
			sortable: false,
			hideable: false,
			editor: {
				xtype: 'ui-numberfield',
				validator: 'validators.strLen15',
				decimalPrecision: 2,
				allowDecimals: true,
				replaceComa: true,
				allowBlank: false,
				minValue: 0
			},
			renderer: (value) => edi.utils.displayCurrencyDecimals(value, edi.constants.UKD_PRICE_PRECISION)
		},
		totalWithNDS: {
			text: 'column.gross.amount',
			dataIndex: 'TotalWithNDS',
			flex: 1,
			sortable: false,
			hideable: false,
			editor: {
				xtype: 'ui-numberfield',
				validator: 'validators.strLen15',
				decimalPrecision: 2,
				allowDecimals: true,
				replaceComa: true,
				allowBlank: false,
				minValue: 0
			},
			renderer: (value) => edi.utils.displayCurrencyDecimals(value, edi.constants.UKD_PRICE_PRECISION)
		}
	},
	fns_upd_service_act_lines: {
		lineNumber: {
			text: 'column.line.number',
			dataIndex: 'lineNumber',
			flex: 0.5
		},
		name: {
			text: 'service_act.product.line.item.name',
			flex: 1,
			dataIndex: 'name'
		},
		period: {
			text: 'service_act.product.line.item.period',
			flex: 1,
			dataIndex: 'period'
		},
		currency: {
			text: 'service_act.product.line.item.currency',
			flex: 1,
			dataIndex: 'currency'
		},
		planingSum: {
			text: 'service_act.product.line.item.planingSum',
			flex: 1,
			dataIndex: 'planingSum'
		},
		factSum: {
			text: 'service_act.product.line.item.factSum',
			flex: 1,
			dataIndex: 'factSum'
		},
		sumWithoutNDS: {
			text: 'service_act.product.line.item.sumWithoutNDS',
			flex: 1,
			dataIndex: 'sumWithoutNDS'
		},
		bonusAmountTotal: {
			text: 'service_act.product.line.item.bonusAmountTotal',
			flex: 1,
			dataIndex: 'bonusAmountTotal'
		}
	},
	fns_upd_service_act_summary: {
		totalWithNDS: {
			flex: 1,
			text: 'service_act.product.line.total.totalPay',
			dataIndex: 'totalPay',
			sortable: false,
			hideable: false
		}
	}
});

edi.columns.addColumns({
	corrected_fns_invoice_lines_5_02: {
		parents: 'corrected_fns_invoice_lines',
		config: {
			taxRate: {
				field: {
					xtype: 'combo',
					allowBlank: false,
					displayField: 'name',
					forceSelection: true,
					queryMode: 'local',
					store: {
						model: 'edi.models.SIMPLE',
						data: 'data.fnsNTaxRates'
					},
					valueField: 'id'
				}
			}
		}
	},
	fns_ukd_lines_5_01n_02: {
		parents: 'fns_ukd_lines_5_01n',
		config: {
			taxRate: {
				field: {
					xtype: 'combo',
					allowBlank: false,
					displayField: 'name',
					forceSelection: true,
					queryMode: 'local',
					store: {
						model: 'edi.models.SIMPLE',
						data: 'data.fnsNTaxRates'
					},
					valueField: 'id'
				}
			}
		}
	},
	fns_upd_summary_editable: {
		parents: 'fns_upd_summary',
		config: {
			totalWithoutNDS: {
				tdCls: 'editable',
				editor: {
					xtype: 'ui-numberfield',
					validator: 'validators.amount14',
					decimalPrecision: 2,
					allowDecimals: true,
					replaceComa: true,
					allowBlank: false,
					minValue: 0
				}
			},
			mustPaid: {
				tdCls: 'editable',
				editor: {
					xtype: 'ui-numberfield',
					validator: function (value) {
						return parseFloat(value) >= 0 && String(value).length <= 14
							? true
							: edi.i18n.getMessage('invalid.field.value');
					},
					decimalPrecision: 2,
					allowDecimals: true,
					replaceComa: true,
					allowBlank: false,
					minValue: 0
				}
			},
			totalWithNDS: {
				tdCls: 'editable',
				editor: {
					xtype: 'ui-numberfield',
					validator: 'validators.amount14',
					decimalPrecision: 2,
					allowDecimals: true,
					replaceComa: true,
					allowBlank: false,
					minValue: 0
				}
			}
		}
	},
	fns_upd_lines_priceWithNDS: {
		parents: 'fns_upd_lines',
		config: {
			priceWithNDS: {
				text: 'column.unit.gross.price',
				dataIndex: 'priceWithNDS',
				flex: 0.75,
				renderer: (value) => edi.utils.displayCurrencyDecimals(value, edi.constants.UPD_PRICE_PRECISION)
			}
		}
	},
	user_cert: {
		id: {
			text: 'column.id',
			dataIndex: 'id',
			flex: 1,
			hidden: true,
			sortable: false
		},
		user: {
			text: 'column.user',
			sortable: false,
			flex: 2,
			renderer: 'user',
			dataIndex: 'user'
		},
		certificate: {
			text: 'column.certificate',
			dataIndex: 'subjectName',
			flex: 2,
			sortable: false,
			renderer: function (objData) {
				var name = objData.lastname + ' ' + objData.firstname,
					position = objData.position ? ', ' + objData.position : '',
					cn = objData.commonName ? ', ' + objData.commonName : '';

				return name + position + cn;
			}
		}
	}
});
